// ManufacturingHelmholzSubcategoryPage.js

import React, { useState } from "react";
import { Helmet } from 'react-helmet';
// import favicon from "../public/favicon.ico";
// import manifest from "../public/manifest.json";
import "./ManufacturingHelmholzSubcategoryPage.css";
import ManufacturingCategoryPageNavBar from "../ManufacturingCategoryPageNavBar";
import ManufacturingProductComponent from "../../ManufacturingComponents/ManufacturingProductComponent";
import ManufacturingHeader from "../../ManufacturingComponents/ManufacturingHeader";
import ManufacturingFooter from "../../ManufacturingComponents/ManufacturingFooter";
import NETLinkPRO from "../../../assets/pdf/NETLink-PRO-Compact.pdf";
import ContactUsBot from "../../../Common Components/ContactUsBot";
import Whatsapp from "../../../Common Components/Whatsapp";
import SwitchtoEnterprise from "../../ManufacturingComponents/SwitchtoEnterprise";

const ManufacturingHelmholzSubcategoryPage = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [currentSubproduct, setCurrentSubproduct] = useState(1);
  const productsPerPage = 4;

  // const subproductNames = {
  //   1: "Protocol converters",
  // };

  const productDataList1 = [
    // NETLink PRO Compact
    {
      images: [
        require("../../../assets/productImages/NETLink-PRO-Compact.png"),
        require("../../../assets/productImages/Netlink-Pro-Compact-2.jpeg"),
        require("../../../assets/productImages/NETLink-PRO-Compact.png"),
        require("../../../assets/productImages/Netlink-Pro-Compact-2.jpeg"),
      ],
      title: "NETLink PRO",
      about:
        "In general, connection to each MPI/PROFIBUS terminal of the bus system or directly at the interfaces of active and passive participants is also possible.",
      manufactiringFeature: (
        <>
          <li>RFC 1006 (ISO on TCP)</li>
          <li>CPU-to-CPU communication (with S7-300* and S7-400*)</li>
          <li>Support for all common S7 engineering tools</li>
        </>
      ),
      downloadLink: NETLinkPRO,
      hyperlinks: [],
    },
  ];

  const getProductList = () => {
    switch (currentSubproduct) {
      case 1:
        return productDataList1;
      default:
        return [];
    }
  };

  const indexOfLastProduct = currentPage * productsPerPage;
  const indexOfFirstProduct = indexOfLastProduct - productsPerPage;
  const currentProducts = getProductList().slice(
    indexOfFirstProduct,
    indexOfLastProduct
  );

  const paginate = (pageNumber) => {
    setCurrentPage(pageNumber);
    window.scrollTo(0, 0);
  };

  const switchSubproduct = (subproductNumber) => {
    setCurrentSubproduct(subproductNumber);
    setCurrentPage(1);
    window.scrollTo(0, 0);
  };

  return (
    <div className="helmholz-page">

      <Helmet>
        <title>Helmholz Products Dealer Distibutor in Pune Mumbai Maharashtra India</title>
        <meta name="description" content="Discover Helmholz's NETLink PRO for industrial edge connectivity at Calidtech. Enhance your network infrastructure with reliable and advanced connectivity solutions. Serving Mumbai, Pune, and across India." />
        <meta name="keywords" content="Calid Technologies, enterprise solutions, factory digital transformations, digital technology, innovation" />
        <meta name="author" content="Calid Technologies" />
        <meta name="robots" content="index, follow" />

        {/* Dealer Tags */}
        <meta name="keywords" content="Helmholz Products Dealer in Pune" />
        <meta name="keywords" content="Best Helmholz Products Dealer in Pune" />
        <meta name="keywords" content="Helmholz Products Distributor in Pune" />

        <meta name="keywords" content="Helmholz Products Dealer in Mumbai" />
        <meta name="keywords" content="Best Helmholz Products Dealer in Mumbai" />
        <meta name="keywords" content="Helmholz Products Distributor in Mumbai" />

        <meta name="keywords" content="Helmholz Products Dealer in Maharashtra" />
        <meta name="keywords" content="Best Helmholz Products Dealer in Maharashtra" />
        <meta name="keywords" content="Helmholz Products Distributor in Maharashtra" />

        <meta name="keywords" content="Helmholz Products Dealer in Vadodara" />
        <meta name="keywords" content="Best Helmholz Products Dealer in Vadodara" />
        <meta name="keywords" content="Helmholz Products Distributor in Vadodara" />

        <meta name="keywords" content="Helmholz Products Dealer in Surat" />
        <meta name="keywords" content="Best Helmholz Products Dealer in Surat" />
        <meta name="keywords" content="Helmholz Products Distributor in Surat" />

        <meta name="keywords" content="Helmholz Products Dealer in Ahmedabad" />
        <meta name="keywords" content="Best Helmholz Products Dealer in Ahmedabad" />
        <meta name="keywords" content="Helmholz Products Distributor in Ahmedabad" />

        <meta name="keywords" content="Helmholz Products Dealer in Indore" />
        <meta name="keywords" content="Best Helmholz Products Dealer in Indore" />
        <meta name="keywords" content="Helmholz Products Distributor in Indore" />

        <meta name="keywords" content="Helmholz Products Dealer in Jaipur" />
        <meta name="keywords" content="Best Helmholz Products Dealer in Jaipur" />
        <meta name="keywords" content="Helmholz Products Distributor in Jaipur" />

        <meta name="keywords" content="Helmholz Products Dealer in Noida" />
        <meta name="keywords" content="Best Helmholz Products Dealer in Noida" />
        <meta name="keywords" content="Helmholz Products Distributor in Noida" />

        <meta name="keywords" content="Helmholz Products Dealer in Gurugram" />
        <meta name="keywords" content="Best Helmholz Products Dealer in Gurugram" />
        <meta name="keywords" content="Helmholz Products Distributor in Gurugram" />

        <meta name="keywords" content="Helmholz Products Dealer in Delhi" />
        <meta name="keywords" content="Best Helmholz Products Dealer in Delhi" />
        <meta name="keywords" content="Helmholz Products Distributor in Delhi" />

        <meta name="keywords" content="Helmholz Products Dealer in Kolkata" />
        <meta name="keywords" content="Best Helmholz Products Dealer in Kolkata" />
        <meta name="keywords" content="Helmholz Products Distributor in Kolkata" />

        <meta name="keywords" content="Helmholz Products Dealer in Bhubaneswar" />
        <meta name="keywords" content="Best Helmholz Products Dealer in Bhubaneswar" />
        <meta name="keywords" content="Helmholz Products Distributor in Bhubaneswar" />

        <meta name="keywords" content="Helmholz Products Dealer in Hyderabad" />
        <meta name="keywords" content="Best Helmholz Products Dealer in Hyderabad" />
        <meta name="keywords" content="Helmholz Products Distributor in Hyderabad" />

        <meta name="keywords" content="Helmholz Products Dealer in Visakhapatnam" />
        <meta name="keywords" content="Best Helmholz Products Dealer in Visakhapatnam" />
        <meta name="keywords" content="Helmholz Products Distributor in Visakhapatnam" />

        <meta name="keywords" content="Helmholz Products Dealer in Chennai" />
        <meta name="keywords" content="Best Helmholz Products Dealer in Chennai" />
        <meta name="keywords" content="Helmholz Products Distributor in Chennai" />

        <meta name="keywords" content="Helmholz Products Dealer in Coimbatore" />
        <meta name="keywords" content="Best Helmholz Products Dealer in Coimbatore" />
        <meta name="keywords" content="Helmholz Products Distributor in Coimbatore" />

        <meta name="keywords" content="Helmholz Products Dealer in Bengaluru" />
        <meta name="keywords" content="Best Helmholz Products Dealer in Bengaluru" />
        <meta name="keywords" content="Helmholz Products Distributor in Bengaluru" />

        <meta name="keywords" content="Helmholz Products Dealer in Tamil Nadu" />
        <meta name="keywords" content="Best Helmholz Products Dealer in Tamil Nadu" />
        <meta name="keywords" content="Helmholz Products Distributor in Tamil Nadu" />

        <meta name="keywords" content="Helmholz Products Dealer in Karnataka" />
        <meta name="keywords" content="Best Helmholz Products Dealer in Karnataka" />
        <meta name="keywords" content="Helmholz Products Distributor in Karnataka" />

        <meta property="og:type" content="website" />
        <meta property="og:title" content="Calid Technologies" />
        <meta property="og:description" content="Discover Helmholz's NETLink PRO for industrial edge connectivity at Calidtech. Enhance your network infrastructure with reliable and advanced connectivity solutions. Serving Mumbai, Pune, and across India." />
        <meta property="og:image" content="https://calidtech.com/og-image.jpg" />
        <meta property="og:url" content="https://calidtech.com" />
        <meta property="og:site_name" content="Calid Technologies" />
        <meta property="fb:app_id" content="Your Facebook App ID" />

        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="FDT Helmholz" />
        <meta name="twitter:description" content="Discover Helmholz's NETLink PRO for industrial edge connectivity at Calidtech. Enhance your network infrastructure with reliable and advanced connectivity solutions. Serving Mumbai, Pune, and across India." />
        <meta name="twitter:image" content="URL to your image" />
        <meta name="twitter:site" content="@YourTwitterHandle" />
        <meta name="twitter:creator" content="@YourTwitterHandle" />

        <meta itemprop="name" content="Calid Technologies" />
        <meta itemprop="description" content="Discover Helmholz's NETLink PRO for industrial edge connectivity at Calidtech. Enhance your network infrastructure with reliable and advanced connectivity solutions. Serving Mumbai, Pune, and across India." />
        <meta itemprop="image" content="https://www.calidtech.com/static/media/logo_bgremove.2c4802e6a4a1a143d254.png" />

        <meta name="mobile-web-app-capable" content="yes" />
        <meta name="apple-mobile-web-app-capable" content="yes" />
        <meta name="apple-mobile-web-app-status-bar-style" content="black-translucent" />
        <meta name="apple-mobile-web-app-title" content="Calid Technologies" />
        <meta name="application-name" content="Calid Technologies" />

        <meta name="msapplication-TileColor" content="#ffffff" />
        <meta name="msapplication-TileImage" content="https://www.calidtech.com/static/media/logo_bgremove.2c4802e6a4a1a143d254.png" />
        <meta name="theme-color" content="#ffffff" />

        <link rel="icon" href="/favicon.ico" />
        <link rel="apple-touch-icon" href="/apple-touch-icon.png" />
        <link rel="manifest" href="/manifest.json" />
        <link rel="canonical" href="https://calidtech.com/ManufacturingHelmholzSubcategoryPage" />
      </Helmet>


      <ManufacturingHeader />
      <ManufacturingCategoryPageNavBar />
      <div className="manufacturing-small-heder-navbar">
        {/* {subproductNames[currentSubproduct]} */}
        Industrial Edge Connectivity - Helmholz
      </div>
      <div className="helmholz-subproduct-nav">
        <button
          className={`helmholz-subproduct-nav-button ${currentSubproduct === 1 ? "active-helmholz-subproduct" : ""
            }`}
          onClick={() => switchSubproduct(1)}
        >
          Protocol converters
        </button>
      </div>

      {currentProducts.map((productData, index) => (
        <ManufacturingProductComponent key={index} {...productData} />
      ))}
      <div className="pagination">
        {getProductList().length > productsPerPage && (
          <ul className="helmholz-ul">
            {Array(Math.ceil(getProductList().length / productsPerPage))
              .fill()
              .map((_, i) => (
                <li
                  id="-helmholz-li"
                  key={i}
                  className={currentPage === i + 1 ? "active-helmholz" : ""}
                >
                  <button
                    className="helmholz-button"
                    onClick={() => paginate(i + 1)}
                  >
                    {i + 1}
                  </button>
                </li>
              ))}
          </ul>
        )}
      </div>
      <ContactUsBot />
      <Whatsapp />
      <SwitchtoEnterprise />
      <ManufacturingFooter />
    </div>
  );
};

export default ManufacturingHelmholzSubcategoryPage;
