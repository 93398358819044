// ManufacturingSmartEnergyMetersSubcategoryPage.js

import React, { useState } from "react";
import { Helmet } from 'react-helmet';
// import favicon from "../public/favicon.ico";
// import manifest from "../public/manifest.json";
import "./ManufacturingSmartEnergyMetersSubcategoryPage.css";
import ManufacturingCategoryPageNavBar from "../ManufacturingCategoryPageNavBar";
import ManufacturingProductComponent from "../../ManufacturingComponents/ManufacturingProductComponent";
import ManufacturingHeader from "../../ManufacturingComponents/ManufacturingHeader";
import ManufacturingFooter from "../../ManufacturingComponents/ManufacturingFooter";
import Inesh from "../../../assets/pdf/Inesh.pdf";
import ContactUsBot from "../../../Common Components/ContactUsBot";
import SwitchtoEnterprise from "../../ManufacturingComponents/SwitchtoEnterprise";
import Whatsapp from "../../../Common Components/Whatsapp";

const ManufacturingSmartEnergyMetersSubcategoryPage = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [currentSubproduct, setCurrentSubproduct] = useState(1);
  const productsPerPage = 4;

  // const subproductNames = {
  //   1: "Inesh",
  // };

  const productDataList1 = [
    //Inesh
    {
      images: [
        require("../../../assets/productImages/Inesh-1.png"),
        require("../../../assets/productImages/Inesh-1.png"),
        require("../../../assets/productImages/Inesh-1.png"),
        require("../../../assets/productImages/Inesh-1.png"),
      ],
      title: "Inesh",
      about:
        "Inesh Smart Meters Supports all the Three Modes on real time. Pluggable Communication Module either GPRS-4G or RF which can be Swapped in the field without changing the Meter.",
      manufactiringFeature: (
        <>
          <li>Multi-Vender HES Compatible.</li>
          <li>Programmable TOU/TOD</li>
          <li>Anti-Tampers and Tamper Alerts</li>
        </>
      ),
      downloadLink: Inesh,
      hyperlinks: [
        {
          link: "TataCommunicationsCaseStudyPage",
          name: "Tata Communication Case Study",
        },
      ],
    },
  ];

  const getProductList = () => {
    switch (currentSubproduct) {
      case 1:
        return productDataList1;
      default:
        return [];
    }
  };

  const indexOfLastProduct = currentPage * productsPerPage;
  const indexOfFirstProduct = indexOfLastProduct - productsPerPage;
  const currentProducts = getProductList().slice(
    indexOfFirstProduct,
    indexOfLastProduct
  );

  const paginate = (pageNumber) => {
    setCurrentPage(pageNumber);
    window.scrollTo(0, 0);
  };

  const switchSubproduct = (subproductNumber) => {
    setCurrentSubproduct(subproductNumber);
    setCurrentPage(1);
    window.scrollTo(0, 0);
  };

  return (
    <div className="smartEnergyMeters-page">

      <Helmet>
        <title>Smart Energy Meters Dealer Distibutor in Pune Mumbai Maharashtra India</title>
        <meta name="description" content="Calid Technologies offers innovative enterprise solutions and factory digital transformations." />
        <meta name="keywords" content="Calid Technologies, enterprise solutions, factory digital transformations, digital technology, innovation" />
        <meta name="author" content="Calid Technologies" />
        <meta name="robots" content="index, follow" />

        {/* Dealer Tags */}
        <meta name="keywords" content="Smart Energy Meter Dealer in Pune" />
        <meta name="keywords" content="Best Smart Energy Meter Dealer in Pune" />
        <meta name="keywords" content="Smart Energy Meter Distributor in Pune" />

        <meta name="keywords" content="Smart Energy Meter Dealer in Mumbai" />
        <meta name="keywords" content="Best Smart Energy Meter Dealer in Mumbai" />
        <meta name="keywords" content="Smart Energy Meter Distributor in Mumbai" />

        <meta name="keywords" content="Smart Energy Meter Dealer in Maharashtra" />
        <meta name="keywords" content="Best Smart Energy Meter Dealer in Maharashtra" />
        <meta name="keywords" content="Smart Energy Meter Distributor in Maharashtra" />

        <meta name="keywords" content="Smart Energy Meter Dealer in Vadodara" />
        <meta name="keywords" content="Best Smart Energy Meter Dealer in Vadodara" />
        <meta name="keywords" content="Smart Energy Meter Distributor in Vadodara" />

        <meta name="keywords" content="Smart Energy Meter Dealer in Surat" />
        <meta name="keywords" content="Best Smart Energy Meter Dealer in Surat" />
        <meta name="keywords" content="Smart Energy Meter Distributor in Surat" />

        <meta name="keywords" content="Smart Energy Meter Dealer in Ahmedabad" />
        <meta name="keywords" content="Best Smart Energy Meter Dealer in Ahmedabad" />
        <meta name="keywords" content="Smart Energy Meter Distributor in Ahmedabad" />

        <meta name="keywords" content="Smart Energy Meter Dealer in Indore" />
        <meta name="keywords" content="Best Smart Energy Meter Dealer in Indore" />
        <meta name="keywords" content="Smart Energy Meter Distributor in Indore" />

        <meta name="keywords" content="Smart Energy Meter Dealer in Jaipur" />
        <meta name="keywords" content="Best Smart Energy Meter Dealer in Jaipur" />
        <meta name="keywords" content="Smart Energy Meter Distributor in Jaipur" />

        <meta name="keywords" content="Smart Energy Meter Dealer in Noida" />
        <meta name="keywords" content="Best Smart Energy Meter Dealer in Noida" />
        <meta name="keywords" content="Smart Energy Meter Distributor in Noida" />

        <meta name="keywords" content="Smart Energy Meter Dealer in Gurugram" />
        <meta name="keywords" content="Best Smart Energy Meter Dealer in Gurugram" />
        <meta name="keywords" content="Smart Energy Meter Distributor in Gurugram" />

        <meta name="keywords" content="Smart Energy Meter Dealer in Delhi" />
        <meta name="keywords" content="Best Smart Energy Meter Dealer in Delhi" />
        <meta name="keywords" content="Smart Energy Meter Distributor in Delhi" />

        <meta name="keywords" content="Smart Energy Meter Dealer in Kolkata" />
        <meta name="keywords" content="Best Smart Energy Meter Dealer in Kolkata" />
        <meta name="keywords" content="Smart Energy Meter Distributor in Kolkata" />

        <meta name="keywords" content="Smart Energy Meter Dealer in Bhubaneswar" />
        <meta name="keywords" content="Best Smart Energy Meter Dealer in Bhubaneswar" />
        <meta name="keywords" content="Smart Energy Meter Distributor in Bhubaneswar" />

        <meta name="keywords" content="Smart Energy Meter Dealer in Hyderabad" />
        <meta name="keywords" content="Best Smart Energy Meter Dealer in Hyderabad" />
        <meta name="keywords" content="Smart Energy Meter Distributor in Hyderabad" />

        <meta name="keywords" content="Smart Energy Meter Dealer in Visakhapatnam" />
        <meta name="keywords" content="Best Smart Energy Meter Dealer in Visakhapatnam" />
        <meta name="keywords" content="Smart Energy Meter Distributor in Visakhapatnam" />

        <meta name="keywords" content="Smart Energy Meter Dealer in Chennai" />
        <meta name="keywords" content="Best Smart Energy Meter Dealer in Chennai" />
        <meta name="keywords" content="Smart Energy Meter Distributor in Chennai" />

        <meta name="keywords" content="Smart Energy Meter Dealer in Coimbatore" />
        <meta name="keywords" content="Best Smart Energy Meter Dealer in Coimbatore" />
        <meta name="keywords" content="Smart Energy Meter Distributor in Coimbatore" />

        <meta name="keywords" content="Smart Energy Meter Dealer in Bengaluru" />
        <meta name="keywords" content="Best Smart Energy Meter Dealer in Bengaluru" />
        <meta name="keywords" content="Smart Energy Meter Distributor in Bengaluru" />

        <meta name="keywords" content="Smart Energy Meter Dealer in Tamil Nadu" />
        <meta name="keywords" content="Best Smart Energy Meter Dealer in Tamil Nadu" />
        <meta name="keywords" content="Smart Energy Meter Distributor in Tamil Nadu" />

        <meta name="keywords" content="Smart Energy Meter Dealer in Karnataka" />
        <meta name="keywords" content="Best Smart Energy Meter Dealer in Karnataka" />
        <meta name="keywords" content="Smart Energy Meter Distributor in Karnataka" />



        <meta property="og:type" content="website" />
        <meta property="og:title" content="Calid Technologies" />
        <meta property="og:description" content="Calid Technologies offers innovative enterprise solutions and factory digital transformations." />
        <meta property="og:image" content="https://calidtech.com/og-image.jpg" />
        <meta property="og:url" content="https://calidtech.com" />
        <meta property="og:site_name" content="Calid Technologies" />
        <meta property="fb:app_id" content="Your Facebook App ID" />

        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="Tata Communication Case Study" />
        <meta name="twitter:description" content="Discover how CalidTech delivers advanced Smart Energy Meters for Automatic Meter Reading (AMR). Our solutions streamline energy management and improve accuracy for efficient and reliable utility monitoring." />
        <meta name="twitter:image" content="URL to your image" />
        <meta name="twitter:site" content="@YourTwitterHandle" />
        <meta name="twitter:creator" content="@YourTwitterHandle" />

        <meta itemprop="name" content="Calid Technologies" />
        <meta itemprop="description" content="Calid Technologies offers innovative enterprise solutions and factory digital transformations." />
        <meta itemprop="image" content="https://www.calidtech.com/static/media/logo_bgremove.2c4802e6a4a1a143d254.png" />

        <meta name="mobile-web-app-capable" content="yes" />
        <meta name="apple-mobile-web-app-capable" content="yes" />
        <meta name="apple-mobile-web-app-status-bar-style" content="black-translucent" />
        <meta name="apple-mobile-web-app-title" content="Calid Technologies" />
        <meta name="application-name" content="Calid Technologies" />

        <meta name="msapplication-TileColor" content="#ffffff" />
        <meta name="msapplication-TileImage" content="https://www.calidtech.com/static/media/logo_bgremove.2c4802e6a4a1a143d254.png" />
        <meta name="theme-color" content="#ffffff" />

        <link rel="icon" href="/favicon.ico" />
        <link rel="apple-touch-icon" href="/apple-touch-icon.png" />
        <link rel="manifest" href="/manifest.json" />
        <link rel="canonical" href="https://calidtech.com/ManufacturingSmartEnergyMetersSubcategoryPage" />
      </Helmet>


      <ManufacturingHeader />
      <ManufacturingCategoryPageNavBar />
      <div className="manufacturing-small-heder-navbar">
        {/* {subproductNames[currentSubproduct]} */}
        Communication And Computing - Smart Energy Meters
      </div>
      <div className="smartEnergyMeters-subproduct-nav">
        <button
          className={`smartEnergyMeters-subproduct-nav-button ${currentSubproduct === 1 ? "active-smartEnergyMeters-subproduct" : ""
            }`}
          onClick={() => switchSubproduct(1)}
        >
          Inesh
        </button>
      </div>

      {currentProducts.map((productData, index) => (
        <ManufacturingProductComponent key={index} {...productData} />
      ))}
      <div className="pagination">
        {getProductList().length > productsPerPage && (
          <ul className="smartEnergyMeters-ul">
            {Array(Math.ceil(getProductList().length / productsPerPage))
              .fill()
              .map((_, i) => (
                <li
                  id="-smartEnergyMeters-li"
                  key={i}
                  className={
                    currentPage === i + 1 ? "active-smartEnergyMeters" : ""
                  }
                >
                  <button
                    className="smartEnergyMeters-button"
                    onClick={() => paginate(i + 1)}
                  >
                    {i + 1}
                  </button>
                </li>
              ))}
          </ul>
        )}
      </div>
      <ContactUsBot />
      <Whatsapp />
      <SwitchtoEnterprise />
      <ManufacturingFooter />
    </div>
  );
};

export default ManufacturingSmartEnergyMetersSubcategoryPage;
