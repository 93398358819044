// EnterpriseCambiumSubcategoryPage.js

import React, { useState } from "react";
import "./EnterpriseCambiumSubcategoryPage.css";
import EnterpriceProductComponent from "../../EntertrpriseComponents/EnterpriceProductComponent";
import EnterpriseCategoryPageNavBar from "../EnterpriseCategoryPageNavBar";
import EnterpriseHeader from "../../EntertrpriseComponents/EnterpriseHeader";
import EnterpriseFooter from "../../EntertrpriseComponents/EnterpriseFooter";
import PTP450i from "../../../assets/pdf/PTP-450i.pdf";
import Force30025 from "../../../assets/pdf/Force-300-25.pdf";
import Force425 from "../../../assets/pdf/Force-425.pdf";
import ContactUsBot from "../../../Common Components/ContactUsBot";
import Whatsapp from "../../../Common Components/Whatsapp";
import SwitchtoManufacturing from "../../EntertrpriseComponents/SwitchtoManufacturing";
import { Helmet } from 'react-helmet';

const EnterpriseCambiumSubcategoryPage = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [currentSubproduct, setCurrentSubproduct] = useState(1);
  const productsPerPage = 4;

  // const subproductNames = {
  //   1: "PTP and PtMP - Cambium",
  // };

  const productDataList1 = [
    //PTP 450i
    {
      images: [
        require("../../../assets/productImages/PTP-450i-1.png"),
        require("../../../assets/productImages/PTP-450i-2.png"),
        require("../../../assets/productImages/PTP-450i-1.png"),
        require("../../../assets/productImages/PTP-450i-2.png"),
      ],
      title: "PTP 450i",
      about: (
        <>
          <p>2x2 OFDM MIMO radio capable of up to 300 Mbps per sector, Designed to meet IP-66 and IP-67 standards to withstand harsh environments. Optional ATEX/HAZLOC certified models available for hazardous.</p>
          <p>Calid Technologies is authorized partner, reseller, supplier, vendor and service provider of PTP 450i in Telangana, Gujarat, Bangalore, Goa, Noida, Rohtak, Gurgaon. Contact us to avail best prices and discount for PTP 450i.
          </p>

        </>
      ),
      enterpriseFeature: (
        <>
          <li>Ultra-wide band radios: 5 GHz or 3 GHz.</li>
          <li>Rugged metal enclosure.</li>
          <li>2x2 OFDM MIMO radio capable of up to 300 Mbps per sector.</li>
        </>
      ),
      downloadLink: PTP450i,
      hyperlinks: [],
    },

    //Force 300-25
    {
      images: [
        require("../../../assets/productImages/Force-300-25-1.png"),
        require("../../../assets/productImages/Force-300-25-1.png"),
        require("../../../assets/productImages/Force-300-25-1.png"),
        require("../../../assets/productImages/Force-300-25-1.png"),
      ],
      title: "Force 300-25",
      about: (
        <>
          <p>ePMP Force 300-25 is designed to operate in high interference environments and provides superior throughput of over 500 Mbps of real user data.</p>
          <p>Calid Technologies is authorized partner, reseller, supplier, vendor and service provider of Force 300-25 in Telangana, Gujarat, Bangalore, Goa, Noida, Rohtak, Gurgaon. Contact us to avail best prices and discount for Force 300-25."
          </p>
        </>
      ),
      enterpriseFeature: (
        <>
          <li>
            Multiple levels of QoS (Quality of Service) for voice, video, and
            data applications supporting a wide variety of service packages for
            both residential and enterprise users.
          </li>
        </>
      ),
      downloadLink: Force30025,
      hyperlinks: [],
    },

    //Force 425
    {
      images: [
        require("../../../assets/productImages/Force-425-1.png"),
        require("../../../assets/productImages/Force-425-1.png"),
        require("../../../assets/productImages/Force-425-1.png"),
        require("../../../assets/productImages/Force-425-1.png"),
      ],
      title: "Force 425",
      about: (
        <>
          <p>Point to point solution delivering up to 1 Gbps throughput at a breakthrough price point based on the latest 802.11ax standards. Cambium Networks the ePMP Force 425 point to point solution.</p>
          <p>Calid Technologies is authorized partner, reseller, supplier, vendor and service provider of ABC Force 425 in Telangana, Gujarat, Bangalore, Goa, Noida, Rohtak, Gurgaon. Contact us to avail best prices and discount for Force 425"</p>
        </>
      ),
      enterpriseFeature: (
        <>
          <li>
            Force 425/400C is built for the real world with an IP67
            ruggedization, simplified mounting scheme and Cambium's three-year
            hardware warranty.
          </li>
        </>
      ),
      downloadLink: Force425,
      hyperlinks: [],
    },
  ];

  const getProductList = () => {
    switch (currentSubproduct) {
      case 1:
        return productDataList1;
      default:
        return [];
    }
  };

  const indexOfLastProduct = currentPage * productsPerPage;
  const indexOfFirstProduct = indexOfLastProduct - productsPerPage;
  const currentProducts = getProductList().slice(
    indexOfFirstProduct,
    indexOfLastProduct
  );

  const paginate = (pageNumber) => {
    setCurrentPage(pageNumber);
    window.scrollTo(0, 0);
  };

  const switchSubproduct = (subproductNumber) => {
    setCurrentSubproduct(subproductNumber);
    setCurrentPage(1);
    window.scrollTo(0, 0);
  };

  return (
    <div className="cambium-enterprise-page">
      <Helmet>
        <title>Cambium Network Products Dealer Distibutor in Pune Mumbai Maharashtra India</title>
        <meta name="description" content="Your website description here" />
        {/* Dealer Tags */}
        <meta name="keywords" content="Cambium Network Products Dealer in Pune" />
        <meta name="keywords" content="Best Cambium Network Products Dealer in Pune" />
        <meta name="keywords" content="Cambium Network Products Distributor in Pune" />

        <meta name="keywords" content="Cambium Network Products Dealer in Mumbai" />
        <meta name="keywords" content="Best Cambium Network Products Dealer in Mumbai" />
        <meta name="keywords" content="Cambium Network Products Distributor in Mumbai" />

        <meta name="keywords" content="Cambium Network Products Dealer in Maharashtra" />
        <meta name="keywords" content="Best Cambium Network Products Dealer in Maharashtra" />
        <meta name="keywords" content="Cambium Network Products Distributor in Maharashtra" />

        <meta name="keywords" content="Cambium Network Products Dealer in Vadodara" />
        <meta name="keywords" content="Best Cambium Network Products Dealer in Vadodara" />
        <meta name="keywords" content="Cambium Network Products Distributor in Vadodara" />

        <meta name="keywords" content="Cambium Network Products Dealer in Surat" />
        <meta name="keywords" content="Best Cambium Network Products Dealer in Surat" />
        <meta name="keywords" content="Cambium Network Products Distributor in Surat" />

        <meta name="keywords" content="Cambium Network Products Dealer in Ahmedabad" />
        <meta name="keywords" content="Best Cambium Network Products Dealer in Ahmedabad" />
        <meta name="keywords" content="Cambium Network Products Distributor in Ahmedabad" />

        <meta name="keywords" content="Cambium Network Products Dealer in Indore" />
        <meta name="keywords" content="Best Cambium Network Products Dealer in Indore" />
        <meta name="keywords" content="Cambium Network Products Distributor in Indore" />

        <meta name="keywords" content="Cambium Network Products Dealer in Jaipur" />
        <meta name="keywords" content="Best Cambium Network Products Dealer in Jaipur" />
        <meta name="keywords" content="Cambium Network Products Distributor in Jaipur" />

        <meta name="keywords" content="Cambium Network Products Dealer in Noida" />
        <meta name="keywords" content="Best Cambium Network Products Dealer in Noida" />
        <meta name="keywords" content="Cambium Network Products Distributor in Noida" />

        <meta name="keywords" content="Cambium Network Products Dealer in Gurugram" />
        <meta name="keywords" content="Best Cambium Network Products Dealer in Gurugram" />
        <meta name="keywords" content="Cambium Network Products Distributor in Gurugram" />

        <meta name="keywords" content="Cambium Network Products Dealer in Delhi" />
        <meta name="keywords" content="Best Cambium Network Products Dealer in Delhi" />
        <meta name="keywords" content="Cambium Network Products Distributor in Delhi" />

        <meta name="keywords" content="Cambium Network Products Dealer in Kolkata" />
        <meta name="keywords" content="Best Cambium Network Products Dealer in Kolkata" />
        <meta name="keywords" content="Cambium Network Products Distributor in Kolkata" />

        <meta name="keywords" content="Cambium Network Products Dealer in Bhubaneswar" />
        <meta name="keywords" content="Best Cambium Network Products Dealer in Bhubaneswar" />
        <meta name="keywords" content="Cambium Network Products Distributor in Bhubaneswar" />

        <meta name="keywords" content="Cambium Network Products Dealer in Hyderabad" />
        <meta name="keywords" content="Best Cambium Network Products Dealer in Hyderabad" />
        <meta name="keywords" content="Cambium Network Products Distributor in Hyderabad" />

        <meta name="keywords" content="Cambium Network Products Dealer in Visakhapatnam" />
        <meta name="keywords" content="Best Cambium Network Products Dealer in Visakhapatnam" />
        <meta name="keywords" content="Cambium Network Products Distributor in Visakhapatnam" />

        <meta name="keywords" content="Cambium Network Products Dealer in Chennai" />
        <meta name="keywords" content="Best Cambium Network Products Dealer in Chennai" />
        <meta name="keywords" content="Cambium Network Products Distributor in Chennai" />

        <meta name="keywords" content="Cambium Network Products Dealer in Coimbatore" />
        <meta name="keywords" content="Best Cambium Network Products Dealer in Coimbatore" />
        <meta name="keywords" content="Cambium Network Products Distributor in Coimbatore" />

        <meta name="keywords" content="Cambium Network Products Dealer in Bengaluru" />
        <meta name="keywords" content="Best Cambium Network Products Dealer in Bengaluru" />
        <meta name="keywords" content="Cambium Network Products Distributor in Bengaluru" />

        <meta name="keywords" content="Cambium Network Products Dealer in Tamil Nadu" />
        <meta name="keywords" content="Best Cambium Network Products Dealer in Tamil Nadu" />
        <meta name="keywords" content="Cambium Network Products Distributor in Tamil Nadu" />

        <meta name="keywords" content="Cambium Network Products Dealer in Karnataka" />
        <meta name="keywords" content="Best Cambium Network Products Dealer in Karnataka" />
        <meta name="keywords" content="Cambium Network Products Distributor in Karnataka" />


        <meta name="author" content="Your Name or Company" />
        <meta name="robots" content="index, follow" />
        <meta property="og:type" content="website" />
        <meta property="og:title" content="Your Page Title" />
        <meta property="og:description" content="Your website description here" />
        <meta property="og:image" content="URL to your image" />
        <meta property="og:url" content="https://www.yourwebsite.com" />
        <meta property="og:site_name" content="Your Website Name" />
        <meta property="fb:app_id" content="Your Facebook App ID" />

        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="Your Page Title" />
        <meta name="twitter:description" content="Your website description here" />
        <meta name="twitter:image" content="URL to your image" />
        <meta name="twitter:site" content="@YourTwitterHandle" />
        <meta name="twitter:creator" content="@YourTwitterHandle" />

        <meta itemprop="name" content="Your Page Title" />
        <meta itemprop="description" content="Your website description here" />
        <meta itemprop="image" content="URL to your image" />

        <meta name="mobile-web-app-capable" content="yes" />
        <meta name="apple-mobile-web-app-capable" content="yes" />
        <meta name="apple-mobile-web-app-status-bar-style" content="black-translucent" />
        <meta name="apple-mobile-web-app-title" content="Your App Title" />
        <meta name="application-name" content="Your App Title" />

        <meta name="msapplication-TileColor" content="#ffffff" />
        <meta name="msapplication-TileImage" content="URL to your tile image" />
        <meta name="theme-color" content="#ffffff" />

        <link rel="icon" href="/favicon.ico" />
        <link rel="apple-touch-icon" href="/apple-touch-icon.png" />
        <link rel="manifest" href="/manifest.json" />
        <link rel="canonical" href="https://calidtech.com/EnterpriseCambiumSubcategoryPage" />
      </Helmet>
      <EnterpriseHeader />
      <EnterpriseCategoryPageNavBar />
      <div className="enterprise-small-heder-navbar">
        {/* {subproductNames[currentSubproduct]} */}
        PTP and PtMP UBR - Cambium
      </div>
      <div className="cambium-enterprise-subproduct-nav">
        <button
          className={`cambium-enterprise-subproduct-nav-button ${currentSubproduct === 1
            ? "active-cambium-enterprise-subproduct"
            : ""
            }`}
          onClick={() => switchSubproduct(1)}
        >
          PTP and PtMP - Cambium
        </button>
      </div>

      {currentProducts.map((productData, index) => (
        <EnterpriceProductComponent key={index} {...productData} />
      ))}
      <div className="pagination">
        {getProductList().length > productsPerPage && (
          <ul className="cambium-enterprise-ul">
            {Array(Math.ceil(getProductList().length / productsPerPage))
              .fill()
              .map((_, i) => (
                <li
                  id="cambium-enterprise-li"
                  key={i}
                  className={
                    currentPage === i + 1 ? "active-cambium-enterprise" : ""
                  }
                >
                  <button
                    className="cambium-enterprise-button"
                    onClick={() => paginate(i + 1)}
                  >
                    {i + 1}
                  </button>
                </li>
              ))}
          </ul>
        )}
      </div>
      <ContactUsBot />
      <Whatsapp />
      <SwitchtoManufacturing />
      <EnterpriseFooter />
    </div>
  );
};

export default EnterpriseCambiumSubcategoryPage;
