// ManufacturingIndustrialSwitchesAndRoutersSubcategoryPage.js

import React, { useState } from "react";
import { Helmet } from 'react-helmet';
// import favicon from "../public/favicon.ico";
// import manifest from "../public/manifest.json";
import "./ManufacturingIndustrialSwitchesAndRoutersSubcategoryPage.css";
import ManufacturingCategoryPageNavBar from "../ManufacturingCategoryPageNavBar";
import ManufacturingProductComponent from "../../ManufacturingComponents/ManufacturingProductComponent";
import ManufacturingHeader from "../../ManufacturingComponents/ManufacturingHeader";
import ManufacturingFooter from "../../ManufacturingComponents/ManufacturingFooter";
import RUT300 from "../../../assets/pdf/RUT-300.pdf";
import RUT956 from "../../../assets/pdf/RUT-956.pdf";
import RUTX50 from "../../../assets/pdf/RUTX50.pdf";
import TRB245 from "../../../assets/pdf/TRB245.pdf";
import TRB255 from "../../../assets/pdf/TRB255.pdf";
import TSW202 from "../../../assets/pdf/TSW202.pdf";
import TSW212 from "../../../assets/pdf/TSW212.pdf";
import EDS510A from "../../../assets/pdf/EDS-510A.pdf";
import EDS528E from "../../../assets/pdf/EDS-528E.pdf";
import MDSG4028 from "../../../assets/pdf/MDS-G4028-4XGS.pdf";
import IKSG6824A from "../../../assets/pdf/IKS-G6824A.pdf";
import RKSG4028 from "../../../assets/pdf/RKS-G4028.pdf";
import G4302LTE4 from "../../../assets/pdf/G4302-LTE4.pdf";
import EKI7412G from "../../../assets/pdf/EKI-7412G-4XP.pdf";
import EKI7428G from "../../../assets/pdf/EKI-7428G-4CA.pdf";
import EKI7559MI from "../../../assets/pdf/EKI-7559MI.pdf";
import EKI7708G from "../../../assets/pdf/EKI-7708G-4F.pdf";
import EKI9728G from "../../../assets/pdf/EKI-9728G-4X8CI.pdf";
import EKI9226G from "../../../assets/pdf/EKI-9226G-20FMI.pdf";
import EKI9508E from "../../../assets/pdf/EKI-9508E-ML.pdf";
import ContactUsBot from "../../../Common Components/ContactUsBot";
import Whatsapp from "../../../Common Components/Whatsapp";
import SwitchtoEnterprise from "../../ManufacturingComponents/SwitchtoEnterprise";

const ManufacturingIndustrialSwitchesAndRoutersSubcategoryPage = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [currentSubproduct, setCurrentSubproduct] = useState(1);
  const productsPerPage = 4;

  // const subproductNames = {
  //   1: "Teltonika",
  //   2: "Moxa",
  //   3: "Advantech",
  // };

  const productDataList1 = [
    // RUT 300
    {
      images: [
        require("../../../assets/productImages/RUT-300-1.jpg"),
        require("../../../assets/productImages/RUT-300-2.jpg"),
        require("../../../assets/productImages/RUT-300-3.jpg"),
        require("../../../assets/productImages/RUT-300-4.jpg"),
      ],
      title: "RUT 300",
      about:
        "Captive portal (Hotspot), internal/external Radius server, SMS authorization, internal/external landing page, 9 default customizable themes and option to upload and download customised hotspot.",
      manufactiringFeature: (
        <>
          <li>SSTP client instance support</li>
          <li>ZeroTier VPN client support</li>
          <li>Method of building scalable IPsec VPNs</li>
        </>
      ),
      downloadLink: RUT300,
      hyperlinks: [],
    },

    //RUT 956
    {
      images: [
        require("../../../assets/productImages/RUT-956-1.jpg"),
        require("../../../assets/productImages/RUT-956-2.jpg"),
        require("../../../assets/productImages/RUT-956-3.jpg"),
        require("../../../assets/productImages/RUT-956-4.jpg"),
      ],
      title: "RUT 956",
      about:
        "RUT956 is an industrial router that combines cellular, Wi-Fi, and wired connectivity options with automatic WAN failover and GNSS capabilities.",
      manufactiringFeature: (
        <>
          <li>Band lock, Used band status display</li>
          <li>Balance Internet traffic over multiple WAN connections</li>
          <li>Possibility to mount remote file system via SSH protocol</li>
        </>
      ),
      downloadLink: RUT956,
      hyperlinks: [],
    },

    //RUTX50
    {
      images: [
        require("../../../assets/productImages/RUTX50-1.jpg"),
        require("../../../assets/productImages/RUTX50-2.jpg"),
        require("../../../assets/productImages/RUTX50-3.jpg"),
        require("../../../assets/productImages/RUTX50-4.jpg"),
      ],
      title: "RUTX50",
      about:
        "RUTX50 is a dual-SIM multi-network router offering 5G mobile communication for high-speed and data-heavy applications. Together with 5x Gigabit Ethernet ports and dual-band Wi-Fi, it provides data connection redundancy.",
      manufactiringFeature: (
        <>
          <li>
            Router assigns its mobile WAN IP address to another device on LAN
          </li>
          <li>View all your Firewall statistics, rules, and rule counters</li>
          <li>
            Direct connection (bridge) between mobile ISP and device on LAN
          </li>
        </>
      ),
      downloadLink: RUTX50,
      hyperlinks: [],
    },

    //TRB245
    {
      images: [
        require("../../../assets/productImages/TRB245-1.jpg"),
        require("../../../assets/productImages/TRB245-2.jpg"),
        require("../../../assets/productImages/TRB245-3.jpg"),
        require("../../../assets/productImages/TRB245-4.jpg"),
      ],
      title: "TRB245",
      about:
        "Captive portal, internal/external Radius server, SMS authorization, internal/external landing page, 9 default customizable themes and option to upload and download customised hotspot themes.",
      manufactiringFeature: (
        <>
          <li>SSTP client instance support</li>
          <li>Method of building scalable IPsec VPNs</li>
          <li>ZeroTier VPN client support</li>
        </>
      ),
      downloadLink: TRB245,
      hyperlinks: [],
    },

    //TRB255:
    {
      images: [
        require("../../../assets/productImages/TRB255-1.jpg"),
        require("../../../assets/productImages/TRB255-2.jpg"),
        require("../../../assets/productImages/TRB255-3.jpg"),
        require("../../../assets/productImages/TRB255-4.jpg"),
      ],
      title: "TRB255",
      about:
        "Visual representation of your network, showing which devices are connected to which other devices. Static and dynamic IP allocation, DHCP Relay. View device ports, enable and disable each of them.",
      manufactiringFeature: (
        <>
          <li>
            STinc offers encryption, authentication and compression in it's
            tunnels.
          </li>
          <li>Method of building scalable IPsec VPNs</li>
          <li>SSTP client instance support</li>
        </>
      ),
      downloadLink: TRB255,
      hyperlinks: [],
    },

    //TSW202:
    {
      images: [
        require("../../../assets/productImages/TSW202-1.jpg"),
        require("../../../assets/productImages/TSW202-2.jpg"),
        require("../../../assets/productImages/TSW202-3.jpg"),
        require("../../../assets/productImages/TSW202-4.jpg"),
      ],
      title: "TSW202",
      about:
        "TSW202 is the first-ever Teltonika Networks managed PoE+ Ethernet switch, designed to provide granular network management capabilities while centralising power distribution.",
      manufactiringFeature: (
        <>
          <li>MRP client role, MRP manager role</li>
          <li>Rate limiting, storm control</li>
          <li>Capability to restart PoE in a specific port</li>
        </>
      ),
      downloadLink: TSW202,
      hyperlinks: [],
    },

    //TSW212:
    {
      images: [
        require("../../../assets/productImages/TSW212-1.jpg"),
        require("../../../assets/productImages/TSW212-2.jpg"),
        require("../../../assets/productImages/TSW212-3.jpg"),
        require("../../../assets/productImages/TSW212-4.jpg"),
      ],
      title: "TSW212",
      about:
        "Visual representation of your network, showing which devices are connected to which other devices. Static and dynamic IP allocation, DHCP Relay. View device ports, enable and disable each of them.",
      manufactiringFeature: (
        <>
          <li>
            STinc offers encryption, authentication and compression in it's
            tunnels.
          </li>
          <li>Method of building scalable IPsec VPNs</li>
          <li>SSTP client instance support</li>
        </>
      ),
      downloadLink: TSW212,
      hyperlinks: [],
    },
  ];

  const productDataList2 = [
    //EDS-510A
    {
      images: [
        require("../../../assets/productImages/EDS-510A-1.jpg"),
        require("../../../assets/productImages/EDS-510A-2.jpg"),
        require("../../../assets/productImages/EDS-510A-3.jpg"),
        require("../../../assets/productImages/EDS-510A-4.jpg"),
      ],
      title: "EDS-510A",
      about:
        "The EDS-510A Gigabit managed redundant Ethernet switches are equipped with up to 3 Gigabit Ethernet ports, making them ideal for building a Gigabit Turbo Ring.",
      manufactiringFeature: (
        <>
          <li>IGMP snooping and GMRP for filtering multicast traffic</li>
          <li>Port Trunking for optimum bandwidth utilization</li>
          <li>RMON for proactive and efficient network monitoring</li>
        </>
      ),
      downloadLink: EDS510A,
      hyperlinks: [],
    },

    //EDS-528E
    {
      images: [
        require("../../../assets/productImages/EDS-528E-1.jpg"),
        require("../../../assets/productImages/EDS-528E-1.jpg"),
        require("../../../assets/productImages/EDS-528E-1.jpg"),
        require("../../../assets/productImages/EDS-528E-1.jpg"),
      ],
      title: "EDS-528E",
      about:
        "The EDS-528E standalone, compact 28-port managed Ethernet switches have 4 combo Gigabit ports with built-in RJ45 or SFP slots for Gigabit fiber-optic communication.",
      manufactiringFeature: (
        <>
          <li>IGMP snooping and GMRP for filtering multicast traffic</li>
          <li>Port Trunking for optimum bandwidth utilization</li>
          <li>SNMPv1/v2c/v3 for different levels of network management</li>
        </>
      ),
      downloadLink: EDS528E,
      hyperlinks: [],
    },

    //MDS-G4028-4XGS
    {
      images: [
        require("../../../assets/productImages/MDS-G4028-4XGS-1.jpg"),
        require("../../../assets/productImages/MDS-G4028-4XGS-2.jpg"),
        require("../../../assets/productImages/MDS-G4028-4XGS-3.jpg"),
        require("../../../assets/productImages/MDS-G4028-4XGS-3.jpg"),
      ],
      title: "MDS-G4028-4XGS",
      about:
        "The MDS-G4028-4XGS Series modular switches support 4 10GbE + 24 Gigabit ports, including 4 embedded ports, 6 interface module expansion slots, and 2 power module slots.",
      manufactiringFeature: (
        <>
          <li>
            Multiple interface type 4-port modules for greater versatility
          </li>
          <li>Passive backplane to minimize maintenance efforts</li>
          <li>Rugged die-cast design for use in harsh environments</li>
        </>
      ),
      downloadLink: MDSG4028,
      hyperlinks: [],
    },

    //IKS-G6824A
    {
      images: [
        require("../../../assets/productImages/IKS-G6824A-1.jpg"),
        require("../../../assets/productImages/IKS-G6824A-2.jpg"),
        require("../../../assets/productImages/IKS-G6824A-1.jpg"),
        require("../../../assets/productImages/IKS-G6824A-1.jpg"),
      ],
      title: "IKS-G6824A",
      about:
        "Process automation and transportation automation applications combine data, voice, and video, and consequently require high performance and high reliability.",
      manufactiringFeature: (
        <>
          <li>Layer 3 routing interconnects multiple LAN segments</li>
          <li>24 Gigabit Ethernet ports</li>
          <li>Up to 24 optical fiber connections(SFP slots)</li>
        </>
      ),
      downloadLink: IKSG6824A,
      hyperlinks: [],
    },

    //RKS-G4028
    {
      images: [
        require("../../../assets/productImages/RKS-G4028-1.jpg"),
        require("../../../assets/productImages/RKS-G4028-3.jpg"),
        require("../../../assets/productImages/RKS-G4028-4.jpg"),
        require("../../../assets/productImages/RKS-G4028-5.jpg"),
      ],
      title: "RKS-G4028",
      about:
        "The RKS-G4028 Series is designed to meet the rigorous demands of mission-critical applications for industry and business, such as power substation automation systems, railway applications.",
      manufactiringFeature: (
        <>
          <li>Modular interfaces for flexible connector type combinations</li>
          <li>Support for IEEE 802.3bt PoE for up to 90 W output per port</li>
          <li>-40 to 75°C operating temperature range</li>
        </>
      ),
      downloadLink: RKSG4028,
      hyperlinks: [],
    },

    //G4302-LTE4
    {
      images: [
        require("../../../assets/productImages/G4302-LTE4-1.jpg"),
        require("../../../assets/productImages/G4302-LTE4-2.jpg"),
        require("../../../assets/productImages/G4302-LTE4-3.jpg"),
        require("../../../assets/productImages/G4302-LTE4-4.jpg"),
      ],
      title: "G4302-LTE4",
      about:
        "The OnCell G4302-LTE4 Series is a reliable and powerful secure cellular router with global LTE coverage. This router provides reliable data transfers from serial and Ethernet to a cellular interface.",
      manufactiringFeature: (
        <>
          <li>Integrated LTE Cat. 4 module with US/EU/APAC band support</li>
          <li>Power management support for wake-up time scheduling</li>
          <li>Cellular link redundancy with dual-SIM GuaranLink support</li>
        </>
      ),
      downloadLink: G4302LTE4,
      hyperlinks: [],
    },
  ];

  const productDataList3 = [
    //EKI-7412G-4XP
    {
      images: [
        require("../../../assets/productImages/EKI-7412G-4XP-1.jpg"),
        require("../../../assets/productImages/EKI-7412G-4XP-2.jpg"),
        require("../../../assets/productImages/EKI-7412G-4XP-3.jpg"),
        require("../../../assets/productImages/EKI-7412G-4XP-4.jpg"),
      ],
      title: "EKI-7412G-4XP",
      about:
        "The EKI-7412G-4XP is equipped with 8 Gigabit Ethernet ports plus 4 10G SFP+ ports. It is ideal for large-scale networks like Surveillance Applications, that involve high-resolution videos and images.",
      manufactiringFeature: (
        <>
          <li>8 Gigabit Ethernet Ports and 4 10G SFP Ports</li>
          <li>0 to 60°C operating temperature range</li>
          <li>Supports 8 IEEE802.3af/at PoE ports</li>
        </>
      ),
      downloadLink: EKI7412G,
      hyperlinks: [],
    },

    //EKI-7428G-4CA
    {
      images: [
        require("../../../assets/productImages/EKI-7428G-4CA-1.jpg"),
        require("../../../assets/productImages/EKI-7428G-4CA-2.jpg"),
        require("../../../assets/productImages/EKI-7428G-4CA-3.jpg"),
        require("../../../assets/productImages/EKI-7428G-4CA-4.jpg"),
      ],
      title: "EKI-7428G-4CA",
      about:
        "The EKI-7428G-4CA is an layer 2 full managed switch including 24 Gigabit ports and 4 Gigabit combo ports. It is designed for rack-mount installation with 1U 19 design and suitable for edge to core networks.",
      manufactiringFeature: (
        <>
          <li>24 x Gigabit ports + 4 x Gigabit Copper/SFP combo ports</li>
          <li>SFP socket for easy and flexible fiber expansion</li>
          <li>Management: SNMP v1/v2c/v3, WEB, Telnet, Standard MIB</li>
        </>
      ),
      downloadLink: EKI7428G,
      hyperlinks: [],
    },

    //EKI-7559MI
    {
      images: [
        require("../../../assets/productImages/EKI-7559MI-1.jpg"),
        require("../../../assets/productImages/EKI-7559MI-2.jpg"),
        require("../../../assets/productImages/EKI-7559MI-3.jpg"),
        require("../../../assets/productImages/EKI-7559MI-4.jpg"),
      ],
      title: "EKI-7559MI",
      about:
        "Both the EKI-7554SI/MI and EKI-7559SI/MI support two SC type Fiber ports, EKI-7554SI/MI four Fast Ethernet ports and EKI-7559SI/MI can support up to eight Fast Ethernet ports.",
      manufactiringFeature: (
        <>
          <li>2 x SC-type fiber ports, plus 8 Fast Ethernet ports</li>
          <li>Management: Web, Telnet, Serial Console, SNMP</li>
          <li>Dual 12 ~ 48 VDC power input and 1 Relay Output</li>
        </>
      ),
      downloadLink: EKI7559MI,
      hyperlinks: [],
    },

    //EKI-7708G-4F
    {
      images: [
        require("../../../assets/productImages/EKI-7708G-4F-1.jpg"),
        require("../../../assets/productImages/EKI-7708G-4F-2.jpg"),
        require("../../../assets/productImages/EKI-7708G-4F-3.jpg"),
        require("../../../assets/productImages/EKI-7708G-4F-4.jpg"),
      ],
      title: "EKI-7708G-4F",
      about:
        "The EKI-7708G-4F and EKI-7708G-4FI provide users with abundant port options for connecting to various device types with 4 Gigabit ports and 4 SFP (mini-GBIC) ports.",
      manufactiringFeature: (
        <>
          <li>4 Gigabit ports + 4 SFP ports</li>
          <li>SFP socket for Easy and Flexible Fiber Expansion</li>
          <li>IXM function enables fast deployment</li>
        </>
      ),
      downloadLink: EKI7708G,
      hyperlinks: [],
    },

    //EKI-9728G-4X8CI
    {
      images: [
        require("../../../assets/productImages/EKI-9728G-4X8CI-1.jpg"),
        require("../../../assets/productImages/EKI-9728G-4X8CI-2.jpg"),
        require("../../../assets/productImages/EKI-9728G-4X8CI-3.jpg"),
        require("../../../assets/productImages/EKI-9728G-4X8CI-4.jpg"),
      ],
      title: "EKI-9728G-4X8CI",
      about:
        "The EKI-9728G is an L3 management switch that supports RIP v1/v2, OSPF v1/v2/v3, and VRRP. Its wide operating temperature of -40 ~ 85°C means that it can operate reliably in harsh environments.",
      manufactiringFeature: (
        <>
          <li>L3 function : Static route , RIP v1/v2 , OSPF v2 , VRRP</li>
          <li>SFP socket for easy and flexible fiber expansion</li>
          <li>Security:802.1x HTTPS,SSH and SNMPv3</li>
        </>
      ),
      downloadLink: EKI9728G,
      hyperlinks: [],
    },

    //EKI-9226G-20FMI
    {
      images: [
        require("../../../assets/productImages/EKI-9226G-20FMI-1.jpg"),
        require("../../../assets/productImages/EKI-9226G-20FMI-2.jpg"),
        require("../../../assets/productImages/EKI-9226G-20FMI-3.jpeg"),
        require("../../../assets/productImages/EKI-9226G-20FMI-1.jpg"),
      ],
      title: "EKI-9226G-20FMI",
      about:
        "The EKI-9226G series are designed for power substation automation applications requiring IEC 61850-3 certification. Thanks to its -40 ~ 85˚C wide operating temperature, it can operate well in extremely harsh environments.",
      manufactiringFeature: (
        <>
          <li>-40 ~ 85˚C wide-range operating temperature</li>
          <li>Dual Power input and 2 relay output</li>
        </>
      ),
      downloadLink: EKI9226G,
      hyperlinks: [],
    },

    //EKI-9508E-ML
    {
      images: [
        require("../../../assets/productImages/EKI-9508E-ML-1.jpg"),
        require("../../../assets/productImages/EKI-9508E-ML-3.jpg"),
        require("../../../assets/productImages/EKI-9508E-ML-4.jpg"),
        require("../../../assets/productImages/EKI-9508E-ML-2.jpg"),
      ],
      title: "EKI-9508E-ML",
      about:
        "EKI-9500 series switch is designed for railway application, with rugged and high EMC performance. EKI-9500 series is the suitable networking solution for rolling stock and wayside applications.",
      manufactiringFeature: (
        <>
          <li>8 x M12 D-coded 10/100Mbps ports</li>
          <li>Complies with EN50155 & EN50121-3-2</li>
          <li>Operating temperature range -40 ~ 70°C</li>
        </>
      ),
      downloadLink: EKI9508E,
      hyperlinks: [],
    },
  ];

  const getProductList = () => {
    switch (currentSubproduct) {
      case 1:
        return productDataList1;
      case 2:
        return productDataList2;
      case 3:
        return productDataList3;
      default:
        return [];
    }
  };

  const indexOfLastProduct = currentPage * productsPerPage;
  const indexOfFirstProduct = indexOfLastProduct - productsPerPage;
  const currentProducts = getProductList().slice(
    indexOfFirstProduct,
    indexOfLastProduct
  );

  const paginate = (pageNumber) => {
    setCurrentPage(pageNumber);
    window.scrollTo(0, 0);
  };

  const switchSubproduct = (subproductNumber) => {
    setCurrentSubproduct(subproductNumber);
    setCurrentPage(1);
    window.scrollTo(0, 0);
  };

  return (
    <div className="industrialSwitchesAndRouters-page">

      <Helmet>
        <title>Industrial Switches and Routers</title>
        <meta name="description" content="Calid Technologies offers innovative enterprise solutions and factory digital transformations." />
        <meta name="keywords" content="Calid Technologies, enterprise solutions, factory digital transformations, digital technology, innovation" />
        <meta name="author" content="Calid Technologies" />
        <meta name="robots" content="index, follow" />

        {/* Dealer Tags */}
        <meta name="keywords" content="Industrial Switches And Routers Dealer in Pune" />
        <meta name="keywords" content="Best Industrial Switches And Routers Dealer in Pune" />
        <meta name="keywords" content="Industrial Switches And Routers Distributor in Pune" />

        <meta name="keywords" content="Industrial Switches And Routers Dealer in Mumbai" />
        <meta name="keywords" content="Best Industrial Switches And Routers Dealer in Mumbai" />
        <meta name="keywords" content="Industrial Switches And Routers Distributor in Mumbai" />

        <meta name="keywords" content="Industrial Switches And Routers Dealer in Maharashtra" />
        <meta name="keywords" content="Best Industrial Switches And Routers Dealer in Maharashtra" />
        <meta name="keywords" content="Industrial Switches And Routers Distributor in Maharashtra" />

        <meta name="keywords" content="Industrial Switches And Routers Dealer in Vadodara" />
        <meta name="keywords" content="Best Industrial Switches And Routers Dealer in Vadodara" />
        <meta name="keywords" content="Industrial Switches And Routers Distributor in Vadodara" />

        <meta name="keywords" content="Industrial Switches And Routers Dealer in Surat" />
        <meta name="keywords" content="Best Industrial Switches And Routers Dealer in Surat" />
        <meta name="keywords" content="Industrial Switches And Routers Distributor in Surat" />

        <meta name="keywords" content="Industrial Switches And Routers Dealer in Ahmedabad" />
        <meta name="keywords" content="Best Industrial Switches And Routers Dealer in Ahmedabad" />
        <meta name="keywords" content="Industrial Switches And Routers Distributor in Ahmedabad" />

        <meta name="keywords" content="Industrial Switches And Routers Dealer in Indore" />
        <meta name="keywords" content="Best Industrial Switches And Routers Dealer in Indore" />
        <meta name="keywords" content="Industrial Switches And Routers Distributor in Indore" />

        <meta name="keywords" content="Industrial Switches And Routers Dealer in Jaipur" />
        <meta name="keywords" content="Best Industrial Switches And Routers Dealer in Jaipur" />
        <meta name="keywords" content="Industrial Switches And Routers Distributor in Jaipur" />

        <meta name="keywords" content="Industrial Switches And Routers Dealer in Noida" />
        <meta name="keywords" content="Best Industrial Switches And Routers Dealer in Noida" />
        <meta name="keywords" content="Industrial Switches And Routers Distributor in Noida" />

        <meta name="keywords" content="Industrial Switches And Routers Dealer in Gurugram" />
        <meta name="keywords" content="Best Industrial Switches And Routers Dealer in Gurugram" />
        <meta name="keywords" content="Industrial Switches And Routers Distributor in Gurugram" />

        <meta name="keywords" content="Industrial Switches And Routers Dealer in Delhi" />
        <meta name="keywords" content="Best Industrial Switches And Routers Dealer in Delhi" />
        <meta name="keywords" content="Industrial Switches And Routers Distributor in Delhi" />

        <meta name="keywords" content="Industrial Switches And Routers Dealer in Kolkata" />
        <meta name="keywords" content="Best Industrial Switches And Routers Dealer in Kolkata" />
        <meta name="keywords" content="Industrial Switches And Routers Distributor in Kolkata" />

        <meta name="keywords" content="Industrial Switches And Routers Dealer in Bhubaneswar" />
        <meta name="keywords" content="Best Industrial Switches And Routers Dealer in Bhubaneswar" />
        <meta name="keywords" content="Industrial Switches And Routers Distributor in Bhubaneswar" />

        <meta name="keywords" content="Industrial Switches And Routers Dealer in Hyderabad" />
        <meta name="keywords" content="Best Industrial Switches And Routers Dealer in Hyderabad" />
        <meta name="keywords" content="Industrial Switches And Routers Distributor in Hyderabad" />

        <meta name="keywords" content="Industrial Switches And Routers Dealer in Visakhapatnam" />
        <meta name="keywords" content="Best Industrial Switches And Routers Dealer in Visakhapatnam" />
        <meta name="keywords" content="Industrial Switches And Routers Distributor in Visakhapatnam" />

        <meta name="keywords" content="Industrial Switches And Routers Dealer in Chennai" />
        <meta name="keywords" content="Best Industrial Switches And Routers Dealer in Chennai" />
        <meta name="keywords" content="Industrial Switches And Routers Distributor in Chennai" />

        <meta name="keywords" content="Industrial Switches And Routers Dealer in Coimbatore" />
        <meta name="keywords" content="Best Industrial Switches And Routers Dealer in Coimbatore" />
        <meta name="keywords" content="Industrial Switches And Routers Distributor in Coimbatore" />

        <meta name="keywords" content="Industrial Switches And Routers Dealer in Bengaluru" />
        <meta name="keywords" content="Best Industrial Switches And Routers Dealer in Bengaluru" />
        <meta name="keywords" content="Industrial Switches And Routers Distributor in Bengaluru" />

        <meta name="keywords" content="Industrial Switches And Routers Dealer in Tamil Nadu" />
        <meta name="keywords" content="Best Industrial Switches And Routers Dealer in Tamil Nadu" />
        <meta name="keywords" content="Industrial Switches And Routers Distributor in Tamil Nadu" />

        <meta name="keywords" content="Industrial Switches And Routers Dealer in Karnataka" />
        <meta name="keywords" content="Best Industrial Switches And Routers Dealer in Karnataka" />
        <meta name="keywords" content="Industrial Switches And Routers Distributor in Karnataka" />


        <meta property="og:type" content="website" />
        <meta property="og:title" content="Calid Technologies" />
        <meta property="og:description" content="Calid Technologies offers innovative enterprise solutions and factory digital transformations." />
        <meta property="og:image" content="https://calidtech.com/og-image.jpg" />
        <meta property="og:url" content="https://calidtech.com" />
        <meta property="og:site_name" content="Calid Technologies" />
        <meta property="fb:app_id" content="Your Facebook App ID" />

        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="Tata Communication Case Study" />
        <meta name="twitter:description" content="Discover how CalidTech delivers advanced Smart Energy Meters for Automatic Meter Reading (AMR). Our solutions streamline energy management and improve accuracy for efficient and reliable utility monitoring." />
        <meta name="twitter:image" content="URL to your image" />
        <meta name="twitter:site" content="@YourTwitterHandle" />
        <meta name="twitter:creator" content="@YourTwitterHandle" />
        <meta itemprop="name" content="Calid Technologies" />
        <meta itemprop="description" content="Calid Technologies offers innovative enterprise solutions and factory digital transformations." />
        <meta itemprop="image" content="https://www.calidtech.com/static/media/logo_bgremove.2c4802e6a4a1a143d254.png" />

        <meta name="mobile-web-app-capable" content="yes" />
        <meta name="apple-mobile-web-app-capable" content="yes" />
        <meta name="apple-mobile-web-app-status-bar-style" content="black-translucent" />
        <meta name="apple-mobile-web-app-title" content="Calid Technologies" />
        <meta name="application-name" content="Calid Technologies" />

        <meta name="msapplication-TileColor" content="#ffffff" />
        <meta name="msapplication-TileImage" content="https://www.calidtech.com/static/media/logo_bgremove.2c4802e6a4a1a143d254.png" />
        <meta name="theme-color" content="#ffffff" />

        <link rel="icon" href="/favicon.ico" />
        <link rel="apple-touch-icon" href="/apple-touch-icon.png" />
        <link rel="manifest" href="/manifest.json" />
        <link rel="canonical" href="https://calidtech.com/ManufacturingIndustrialSwitchesAndRoutersSubcategoryPage" />
      </Helmet>



      <ManufacturingHeader />
      <ManufacturingCategoryPageNavBar />
      <div className="manufacturing-small-heder-navbar">
        {/* {subproductNames[currentSubproduct]} */}
        Communication And Computing - Industrial Switches And Routers
      </div>
      <div className="industrialSwitchesAndRouters-subproduct-nav">
        <button
          className={`industrialSwitchesAndRouters-subproduct-nav-button ${currentSubproduct === 1
            ? "active-industrialSwitchesAndRouters-subproduct"
            : ""
            }`}
          onClick={() => switchSubproduct(1)}
        >
          Teltonika
        </button>
        <button
          className={`industrialSwitchesAndRouters-subproduct-nav-button ${currentSubproduct === 2
            ? "active-industrialSwitchesAndRouters-subproduct"
            : ""
            }`}
          onClick={() => switchSubproduct(2)}
        >
          Moxa
        </button>
        <button
          className={`industrialSwitchesAndRouters-subproduct-nav-button ${currentSubproduct === 3
            ? "active-industrialSwitchesAndRouters-subproduct"
            : ""
            }`}
          onClick={() => switchSubproduct(3)}
        >
          Advantech
        </button>
      </div>

      {currentProducts.map((productData, index) => (
        <ManufacturingProductComponent key={index} {...productData} />
      ))}
      <div className="pagination">
        {getProductList().length > productsPerPage && (
          <ul className="industrialSwitchesAndRouters-ul">
            {Array(Math.ceil(getProductList().length / productsPerPage))
              .fill()
              .map((_, i) => (
                <li
                  id="-industrialSwitchesAndRouters-li"
                  key={i}
                  className={
                    currentPage === i + 1
                      ? "active-industrialSwitchesAndRouters"
                      : ""
                  }
                >
                  <button
                    className="industrialSwitchesAndRouters-button"
                    onClick={() => paginate(i + 1)}
                  >
                    {i + 1}
                  </button>
                </li>
              ))}
          </ul>
        )}
      </div>
      <ContactUsBot />
      <Whatsapp />
      <SwitchtoEnterprise />
      <ManufacturingFooter />
    </div>
  );
};

export default ManufacturingIndustrialSwitchesAndRoutersSubcategoryPage;
