// EnterpriseLenovoSubcategoryPage.js

import React, { useState } from "react";
import "./EnterpriseLenovoSubcategoryPage.css";
import EnterpriceProductComponent from "../../EntertrpriseComponents/EnterpriceProductComponent";
import EnterpriseCategoryPageNavBar from "../EnterpriseCategoryPageNavBar";
import EnterpriseHeader from "../../EntertrpriseComponents/EnterpriseHeader";
import EnterpriseFooter from "../../EntertrpriseComponents/EnterpriseFooter";
import ST650 from "../../../assets/pdf/ST650.pdf";
import ST550 from "../../../assets/pdf/ST550.pdf";
import SR250 from "../../../assets/pdf/SR250.pdf";
import SR550 from "../../../assets/pdf/SR550.pdf";
import SR650 from "../../../assets/pdf/SR650.pdf";
import ContactUsBot from "../../../Common Components/ContactUsBot";
import Whatsapp from "../../../Common Components/Whatsapp";
import SwitchtoManufacturing from "../../EntertrpriseComponents/SwitchtoManufacturing";
import { Helmet } from 'react-helmet';


const EnterpriseLenovoSubcategoryPage = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [currentSubproduct, setCurrentSubproduct] = useState(1);
  const productsPerPage = 4;

  // const subproductNames = {
  //   1: "Tower and Rackmount Servers",
  // };

  const productDataList1 = [
    //ST550
    {
      images: [
        require("../../../assets/productImages/ST550.png"),
        require("../../../assets/productImages/ST550.png"),
        require("../../../assets/productImages/ST550.png"),
        require("../../../assets/productImages/ST550.png"),
      ],
      title: "ST550",
      about:
        "The Lenovo ThinkSystem ST550 is a scalable 4U tower server that features two powerful second-generation Intel® Xeon® Scalable family processors.",
      enterpriseFeature: (
        <>
          <li>Flexible and Secure.</li>
          <li>Integrated IT Management.</li>
          <li>Outstanding Performance.</li>
        </>
      ),
      downloadLink: ST550,
      hyperlinks: [],
    },

    //ST650
    {
      images: [
        require("../../../assets/productImages/ST650-1.png"),
        require("../../../assets/productImages/ST650-1.png"),
        require("../../../assets/productImages/ST650-1.png"),
        require("../../../assets/productImages/ST650-1.png"),
      ],
      title: "ST650",
      about:
        "The Lenovo ThinkSystem ST650 V2 is a scalable 4U tower server that features two powerful thirdgeneration Intel® Xeon® Scalable processors. s. It delivers outstanding performance and memory capacity.",
      enterpriseFeature: (
        <>
          <li>Flexible and future-ready.</li>
          <li>Secure by design.</li>
          <li>Integrated IT Management.</li>
        </>
      ),
      downloadLink: ST650,
      hyperlinks: [],
    },

    //SR250
    {
      images: [
        require("../../../assets/productImages/SR250-1.png"),
        require("../../../assets/productImages/SR250-1.png"),
        require("../../../assets/productImages/SR250-1.png"),
        require("../../../assets/productImages/SR250-1.png"),
      ],
      title: "SR250",
      about:
        "The Lenovo ThinkSystem SR250 is a single-processor rack server that integrates power, reliability, flexibility, and security in a compact 1U form factor suited for the small-to-medium business.",
      enterpriseFeature: (
        <>
          <li>Flexible and Scalable.</li>
          <li>Simple Management.</li>
          <li>Powerful and Secure.</li>
        </>
      ),
      downloadLink: SR250,
      hyperlinks: [],
    },

    //SR550
    {
      images: [
        require("../../../assets/productImages/SR550-1.png"),
        require("../../../assets/productImages/SR550-1.png"),
        require("../../../assets/productImages/SR550-1.png"),
        require("../../../assets/productImages/SR550-1.png"),
      ],
      title: "SR550",
      about:
        "The Lenovo ThinkSystem SR550 dual-socket 2U rack server is ideal for small to large organizations that need industry-leading reliability, management, and security.",
      enterpriseFeature: (
        <>
          <li>Scalability and performance.</li>
          <li>Availability and serviceability.</li>
          <li>Manageability and security.</li>
        </>
      ),
      downloadLink: SR550,
      hyperlinks: [],
    },

    //SR650
    {
      images: [
        require("../../../assets/productImages/SR650-1.png"),
        require("../../../assets/productImages/SR650-1.png"),
        require("../../../assets/productImages/SR650-1.png"),
        require("../../../assets/productImages/SR650-1.png"),
      ],
      title: "SR650",
      about:
        "ThinkSystem SR650 supports Intel® Optane™ DC Persistent Memory and up to two next-generation Intel® Xeon® Processor Scalable family CPUs. e. It’s engineered to deliver high performance with 205W CPUs.",
      enterpriseFeature: (
        <>
          <li>For Compute-Intensive Workloads.</li>
          <li>Versatile and Scalable.</li>
          <li>Versatile and Scalable.</li>
        </>
      ),
      downloadLink: SR650,
      hyperlinks: [],
    },
  ];

  const getProductList = () => {
    switch (currentSubproduct) {
      case 1:
        return productDataList1;
      default:
        return [];
    }
  };

  const indexOfLastProduct = currentPage * productsPerPage;
  const indexOfFirstProduct = indexOfLastProduct - productsPerPage;
  const currentProducts = getProductList().slice(
    indexOfFirstProduct,
    indexOfLastProduct
  );

  const paginate = (pageNumber) => {
    setCurrentPage(pageNumber);
    window.scrollTo(0, 0);
  };

  const switchSubproduct = (subproductNumber) => {
    setCurrentSubproduct(subproductNumber);
    setCurrentPage(1);
    window.scrollTo(0, 0);
  };

  return (
    <div className="lenovo-enterprise-page">
      <Helmet>
        <title>lenovo Server Dealer Distibutor in Pune Mumbai Maharashtra India</title>
        <meta name="description" content="Your website description here" />
        {/* Dealer Tags */}
        <meta name="keywords" content="Lenovo Server Dealer in Pune" />
        <meta name="keywords" content="Best Lenovo Server Dealer in Pune" />
        <meta name="keywords" content="Lenovo Server Distributor in Pune" />

        <meta name="keywords" content="Lenovo Server Dealer in Mumbai" />
        <meta name="keywords" content="Best Lenovo Server Dealer in Mumbai" />
        <meta name="keywords" content="Lenovo Server Distributor in Mumbai" />

        <meta name="keywords" content="Lenovo Server Dealer in Maharashtra" />
        <meta name="keywords" content="Best Lenovo Server Dealer in Maharashtra" />
        <meta name="keywords" content="Lenovo Server Distributor in Maharashtra" />

        <meta name="keywords" content="Lenovo Server Dealer in Vadodara" />
        <meta name="keywords" content="Best Lenovo Server Dealer in Vadodara" />
        <meta name="keywords" content="Lenovo Server Distributor in Vadodara" />

        <meta name="keywords" content="Lenovo Server Dealer in Surat" />
        <meta name="keywords" content="Best Lenovo Server Dealer in Surat" />
        <meta name="keywords" content="Lenovo Server Distributor in Surat" />

        <meta name="keywords" content="Lenovo Server Dealer in Ahmedabad" />
        <meta name="keywords" content="Best Lenovo Server Dealer in Ahmedabad" />
        <meta name="keywords" content="Lenovo Server Distributor in Ahmedabad" />

        <meta name="keywords" content="Lenovo Server Dealer in Gujarat" />
        <meta name="keywords" content="Best Lenovo Server Dealer in Gujarat" />
        <meta name="keywords" content="Lenovo Server Distributor in Gujarat" />

        <meta name="keywords" content="Lenovo Server Dealer in Indore" />
        <meta name="keywords" content="Best Lenovo Server Dealer in Indore" />
        <meta name="keywords" content="Lenovo Server Distributor in Indore" />

        <meta name="keywords" content="Lenovo Server Dealer in Madhya Pradesh" />
        <meta name="keywords" content="Best Lenovo Server Dealer in Madhya Pradesh" />
        <meta name="keywords" content="Lenovo Server Distributor in Madhya Pradesh" />

        <meta name="keywords" content="Lenovo Server Dealer in Jaipur" />
        <meta name="keywords" content="Best Lenovo Server Dealer in Jaipur" />
        <meta name="keywords" content="Lenovo Server Distributor in Jaipur" />

        <meta name="keywords" content="Lenovo Server Dealer in Rajasthan" />
        <meta name="keywords" content="Best Lenovo Server Dealer in Rajasthan" />
        <meta name="keywords" content="Lenovo Server Distributor in Rajasthan" />

        <meta name="keywords" content="Lenovo Server Dealer in Noida" />
        <meta name="keywords" content="Best Lenovo Server Dealer in Noida" />
        <meta name="keywords" content="Lenovo Server Distributor in Noida" />

        <meta name="keywords" content="Lenovo Server Dealer in Gurugram" />
        <meta name="keywords" content="Best Lenovo Server Dealer in Gurugram" />
        <meta name="keywords" content="Lenovo Server Distributor in Gurugram" />

        <meta name="keywords" content="Lenovo Server Dealer in Delhi" />
        <meta name="keywords" content="Best Lenovo Server Dealer in Delhi" />
        <meta name="keywords" content="Lenovo Server Distributor in Delhi" />

        <meta name="keywords" content="Lenovo Server Dealer in Kolkata" />
        <meta name="keywords" content="Best Lenovo Server Dealer in Kolkata" />
        <meta name="keywords" content="Lenovo Server Distributor in Kolkata" />

        <meta name="keywords" content="Lenovo Server Dealer in West Bengal" />
        <meta name="keywords" content="Best Lenovo Server Dealer in West Bengal" />
        <meta name="keywords" content="Lenovo Server Distributor in West Bengal" />

        <meta name="keywords" content="Lenovo Server Dealer in Bhubaneswar" />
        <meta name="keywords" content="Best Lenovo Server Dealer in Bhubaneswar" />
        <meta name="keywords" content="Lenovo Server Distributor in Bhubaneswar" />

        <meta name="keywords" content="Lenovo Server Dealer in Odisha" />
        <meta name="keywords" content="Best Lenovo Server Dealer in Odisha" />
        <meta name="keywords" content="Lenovo Server Distributor in Odisha" />

        <meta name="keywords" content="Lenovo Server Dealer in Hyderabad" />
        <meta name="keywords" content="Best Lenovo Server Dealer in Hyderabad" />
        <meta name="keywords" content="Lenovo Server Distributor in Hyderabad" />

        <meta name="keywords" content="Lenovo Server Dealer in Telangana" />
        <meta name="keywords" content="Best Lenovo Server Dealer in Telangana" />
        <meta name="keywords" content="Lenovo Server Distributor in Telangana" />

        <meta name="keywords" content="Lenovo Server Dealer in Visakhapatnam" />
        <meta name="keywords" content="Best Lenovo Server Dealer in Visakhapatnam" />
        <meta name="keywords" content="Lenovo Server Distributor in Visakhapatnam" />

        <meta name="keywords" content="Lenovo Server Dealer in Andhra Pradesh" />
        <meta name="keywords" content="Best Lenovo Server Dealer in Andhra Pradesh" />
        <meta name="keywords" content="Lenovo Server Distributor in Andhra Pradesh" />

        <meta name="keywords" content="Lenovo Server Dealer in Chennai" />
        <meta name="keywords" content="Best Lenovo Server Dealer in Chennai" />
        <meta name="keywords" content="Lenovo Server Distributor in Chennai" />

        <meta name="keywords" content="Lenovo Server Dealer in Coimbatore" />
        <meta name="keywords" content="Best Lenovo Server Dealer in Coimbatore" />
        <meta name="keywords" content="Lenovo Server Distributor in Coimbatore" />

        <meta name="keywords" content="Lenovo Server Dealer in Tamil Nadu" />
        <meta name="keywords" content="Best Lenovo Server Dealer in Tamil Nadu" />
        <meta name="keywords" content="Lenovo Server Distributor in Tamil Nadu" />

        <meta name="keywords" content="Lenovo Server Dealer in Bengaluru" />
        <meta name="keywords" content="Best Lenovo Server Dealer in Bengaluru" />
        <meta name="keywords" content="Lenovo Server Distributor in Bengaluru" />

        <meta name="keywords" content="Lenovo Server Dealer in Karnataka" />
        <meta name="keywords" content="Best Lenovo Server Dealer in Karnataka" />
        <meta name="keywords" content="Lenovo Server Distributor in Karnataka" />



        <meta name="author" content="Your Name or Company" />
        <meta name="robots" content="index, follow" />

        <meta property="og:type" content="website" />
        <meta property="og:title" content="Your Page Title" />
        <meta property="og:description" content="Your website description here" />
        <meta property="og:image" content="URL to your image" />
        <meta property="og:url" content="https://www.yourwebsite.com" />
        <meta property="og:site_name" content="Your Website Name" />
        <meta property="fb:app_id" content="Your Facebook App ID" />

        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="Your Page Title" />
        <meta name="twitter:description" content="Your website description here" />
        <meta name="twitter:image" content="URL to your image" />
        <meta name="twitter:site" content="@YourTwitterHandle" />
        <meta name="twitter:creator" content="@YourTwitterHandle" />

        <meta itemprop="name" content="Your Page Title" />
        <meta itemprop="description" content="Your website description here" />
        <meta itemprop="image" content="URL to your image" />

        <meta name="mobile-web-app-capable" content="yes" />
        <meta name="apple-mobile-web-app-capable" content="yes" />
        <meta name="apple-mobile-web-app-status-bar-style" content="black-translucent" />
        <meta name="apple-mobile-web-app-title" content="Your App Title" />
        <meta name="application-name" content="Your App Title" />

        <meta name="msapplication-TileColor" content="#ffffff" />
        <meta name="msapplication-TileImage" content="URL to your tile image" />
        <meta name="theme-color" content="#ffffff" />

        <link rel="icon" href="/favicon.ico" />
        <link rel="apple-touch-icon" href="/apple-touch-icon.png" />
        <link rel="manifest" href="/manifest.json" />
        <link rel="canonical" href="https://calidtech.com/EnterpriseLenovoSubcategoryPage" />
      </Helmet>
      <EnterpriseHeader />
      <EnterpriseCategoryPageNavBar />
      <div className="enterprise-small-heder-navbar">
        {/* {subproductNames[currentSubproduct]} */}
        Server and Storage Solution - Lenovo
      </div>
      <div className="lenovo-enterprise-subproduct-nav">
        <button
          className={`lenovo-enterprise-subproduct-nav-button ${currentSubproduct === 1 ? "active-lenovo-enterprise-subproduct" : ""
            }`}
          onClick={() => switchSubproduct(1)}
        >
          Tower and Rackmount Servers
        </button>
      </div>

      {currentProducts.map((productData, index) => (
        <EnterpriceProductComponent key={index} {...productData} />
      ))}
      <div className="pagination">
        {getProductList().length > productsPerPage && (
          <ul className="lenovo-enterprise-ul">
            {Array(Math.ceil(getProductList().length / productsPerPage))
              .fill()
              .map((_, i) => (
                <li
                  id="lenovo-enterprise-li"
                  key={i}
                  className={
                    currentPage === i + 1 ? "active-lenovo-enterprise" : ""
                  }
                >
                  <button
                    className="lenovo-enterprise-button"
                    onClick={() => paginate(i + 1)}
                  >
                    {i + 1}
                  </button>
                </li>
              ))}
          </ul>
        )}
      </div>
      <ContactUsBot />
      <Whatsapp />
      <SwitchtoManufacturing />
      <EnterpriseFooter />
    </div>
  );
};

export default EnterpriseLenovoSubcategoryPage;
