// EnterpriseJuniperMistSubcategoryPage.js

import React, { useState } from "react";
import "./EnterpriseJuniperMistSubcategoryPage.css";
import EnterpriceProductComponent from "../../EntertrpriseComponents/EnterpriceProductComponent";
import EnterpriseCategoryPageNavBar from "../EnterpriseCategoryPageNavBar";
import EnterpriseHeader from "../../EntertrpriseComponents/EnterpriseHeader";
import EnterpriseFooter from "../../EntertrpriseComponents/EnterpriseFooter";
import EX230024T from "../../../assets/pdf/EX2300-24T.pdf";
import EX340024T from "../../../assets/pdf/EX3400-24T.pdf";
import EX430024T from "../../../assets/pdf/EX4300-24T.pdf";
import AP32 from "../../../assets/pdf/AP32.pdf";
import AP33 from "../../../assets/pdf/AP33.pdf";
import AP43 from "../../../assets/pdf/AP43.pdf";
import EX230024P from "../../../assets/pdf/EX-2300-24P.pdf";
import EX340024P from "../../../assets/pdf/EX-3400-24P.pdf";
import EX430032F24P from "../../../assets/pdf/EX4300-32F-24P.pdf";
import EX460040F from "../../../assets/pdf/EX4600-40F.pdf";
import ContactUsBot from "../../../Common Components/ContactUsBot";
import Whatsapp from "../../../Common Components/Whatsapp";
import SwitchtoManufacturing from "../../EntertrpriseComponents/SwitchtoManufacturing";
import { Helmet } from 'react-helmet';


const EnterpriseJuniperMistSubcategoryPage = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [currentSubproduct, setCurrentSubproduct] = useState(1);
  const productsPerPage = 4;

  // const subproductNames = {
  //   1: "Access Switches",
  //   2: "Core and Distribution Switch",
  //   3: "Mist WiFi",
  // };

  const productDataList1 = [
    //EX2300-24T
    {
      images: [
        require("../../../assets/productImages/EX2300-24T-1.jpg"),
        require("../../../assets/productImages/EX2300-24T-2.jpg"),
        require("../../../assets/productImages/EX2300-24T-1.jpg"),
        require("../../../assets/productImages/EX2300-24T-2.jpg"),
      ],
      title: "EX2300-24T",
      about: (
        <>
          <p>The Juniper Networks® EX2300 line of Ethernet switches offers a compact, high-performance solution for supporting today's converged network access deployments.</p>
          <p>It is designed to provide efficient network access and reliable performance for modern enterprise environments.</p>
        </>
      ), enterpriseFeature: (
        <>
          <li>
            Complete Layer 2 and basic Layer 3 switching capabilities are
            available.
          </li>
          <li>
            Energy Efficient Ethernet (EEE) support is provided on 1GbE ports.
          </li>
          <li>Reduction of Hazardous Waste (RoHS) is certified.</li>
        </>
      ),
      downloadLink: EX230024T,
      hyperlinks: [],
    },

    //EX2300-24P
    {
      images: [
        require("../../../assets/productImages/EX-2300-24P-1.png"),
        require("../../../assets/productImages/EX-2300-24P-1.png"),
        require("../../../assets/productImages/EX-2300-24P-1.png"),
        require("../../../assets/productImages/EX-2300-24P-1.png"),
      ],
      title: "EX2300-24P",
      about:
        "The Juniper Networks EX2300 Ethernet Switch offers an economical, entry-level, standalone solution for access-layer deployments in branch and remote offices, as well as enterprise campus networks.",
      enterpriseFeature: (
        <>
          <li>Maximum MAC addresses in hardware: 16,000.</li>
          <li>Static routing.</li>
          <li>IEEE 802.3af: PoE.</li>
        </>
      ),
      downloadLink: EX230024P,
      hyperlinks: [],
    },

    //EX3400-24P
    {
      images: [
        require("../../../assets/productImages/EX-3400-24P-1.png"),
        require("../../../assets/productImages/EX-3400-24P-1.png"),
        require("../../../assets/productImages/EX-3400-24P-1.png"),
        require("../../../assets/productImages/EX-3400-24P-1.png"),
      ],
      title: "EX3400-24P",
      about:
        "Juniper Networks EX3400 Ethernet Switch delivers a high-performance, flexible, and cost-effective solution for today's most demanding converged data, voice, and video enterprise access environments.",
      enterpriseFeature: (
        <>
          <li>Redundant trunk group.</li>
          <li>Cross-member link aggregation.</li>
          <li>Nonstop software upgrade (NSSU).</li>
        </>
      ),
      downloadLink: EX340024P,
      hyperlinks: [],
    },

    //EX4300-24P
    {
      images: [
        require("../../../assets/productImages/EX4300-32F-24P-1.png"),
        require("../../../assets/productImages/EX4300-32F-24P-1.png"),
        require("../../../assets/productImages/EX4300-32F-24P-1.png"),
        require("../../../assets/productImages/EX4300-32F-24P-1.png"),
      ],
      title: "EX4300-24P",
      about:
        "IThe EX4300 line of Ethernet switches delivers the performance, flexibility, and scale required for both campus and data center Gigabit Ethernet (GbE) access switch environments.",
      enterpriseFeature: (
        <>
          <li>Redundant power supplies.</li>
          <li>Hot-swappable fans.</li>
          <li>Nonstop bridging and nonstop routing.</li>
        </>
      ),
      downloadLink: EX430032F24P,
      hyperlinks: [],
    },

    //EX3400-24T
    {
      images: [
        require("../../../assets/productImages/EX3400-24T-1.jpg"),
        require("../../../assets/productImages/EX3400-24T-2.jpg"),
        require("../../../assets/productImages/EX3400-24T-1.jpg"),
        require("../../../assets/productImages/EX3400-24T-2.jpg"),
      ],
      title: "EX3400-24T",
      about:
        "The Juniper Networks® EX3400 Ethernet Switch with Juniper Networks Virtual Chassis technology provides enterprises with the flexibility and ease of management.",
      enterpriseFeature: (
        <>
          <li>Energy Efficient Ethernet (EEE) capability is provided.</li>
          <li>
            An easy-to-manage solution includes centralized software upgrades.
          </li>
          <li>
            13.8-inch deep 1 U form factor supports flexible deployment options.
          </li>
        </>
      ),
      downloadLink: EX340024T,
      hyperlinks: [],
    },

    //EX4300-24T
    {
      images: [
        require("../../../assets/productImages/EX4300-24T-1.jpg"),
        require("../../../assets/productImages/EX4300-24T-2.jpg"),
        require("../../../assets/productImages/EX4300-24T-1.jpg"),
        require("../../../assets/productImages/EX4300-24T-2.jpg"),
      ],
      title: "EX4300-24T",
      about:
        "The Juniper Networks® EX4300 line of Ethernet switches with Virtual Chassis technology combines the carrier-class reliability of modular systems with the economics and flexibility of stackable platforms.",
      enterpriseFeature: (
        <>
          <li>Hot-swappable fans</li>
          <li>Modular Juniper Networks Junos® operating system</li>
          <li>Single management interface</li>
        </>
      ),
      downloadLink: EX430024T,
      hyperlinks: [],
    },
  ];

  const productDataList2 = [
    //EX4300-32F
    {
      images: [
        require("../../../assets/productImages/EX4300-32F-24P-1.png"),
        require("../../../assets/productImages/EX4300-32F-24P-1.png"),
        require("../../../assets/productImages/EX4300-32F-24P-1.png"),
        require("../../../assets/productImages/EX4300-32F-24P-1.png"),
      ],
      title: "EX4300-32F",
      about:
        "The EX4300 line of Ethernet switches delivers the performance, flexibility, and scale required for both campus and data center Gigabit Ethernet (GbE) access switch environments.",
      enterpriseFeature: (
        <>
          <li>Redundant trunk group (RTG).</li>
          <li>Cross-member link aggregation.</li>
          <li>Carrier-class hardware.</li>
        </>
      ),
      downloadLink: EX430032F24P,
      hyperlinks: [],
    },

    //EX4600-40F
    {
      images: [
        require("../../../assets/productImages/EX-4600-40F-1.png"),
        require("../../../assets/productImages/EX-4600-40F-1.png"),
        require("../../../assets/productImages/EX-4600-40F-1.png"),
        require("../../../assets/productImages/EX-4600-40F-1.png"),
      ],
      title: "EX4600-40F",
      about:
        "The EX4600 offers fixed 10 Gpbs and modular 10 Gpbs and 40 Gbps ports, giving it the flexibility to also be implemented in data center top-of-rack and service provider aggregation deployments.",
      enterpriseFeature: (
        <>
          <li>Wired service level expectations.</li>
          <li>High performance.</li>
          <li>High availability.</li>
        </>
      ),
      downloadLink: EX460040F,
      hyperlinks: [],
    },
  ];

  const productDataList3 = [
    //AP32
    {
      images: [
        require("../../../assets/productImages/AP32-1.png"),
        require("../../../assets/productImages/AP32-2.png"),
        require("../../../assets/productImages/AP32-1.png"),
        require("../../../assets/productImages/AP32-2.png"),
      ],
      title: "AP32",
      about:
        "The Juniper AP32 access point integrates Mist AI for AX capabilities to automate network operation and boost Wi-Fi performance, delivering an aggregate data rate up to 3.0 Gbps concurrently.",
      enterpriseFeature: (
        <>
          <li>Deployment: Indoor</li>
          <li>Wi-Fi Tri-Radio : Yes</li>
          <li>Antenna Options: Internal, External</li>
        </>
      ),
      downloadLink: AP32,
      hyperlinks: [],
    },

    //AP33
    {
      images: [
        require("../../../assets/productImages/AP33-1.png"),
        require("../../../assets/productImages/AP33-2.png"),
        require("../../../assets/productImages/AP33-1.png"),
        require("../../../assets/productImages/AP33-2.png"),
      ],
      title: "AP33",
      about:
        "The Juniper AP33 access point integrates Mist AI for AX capabilities and a dynamic virtual Bluetooth® LE (vBLE) antenna array to automate network operation and boost Wi-Fi performance.",
      enterpriseFeature: (
        <>
          <li>Deployment: Indoor</li>
          <li>Wi-Fi Tri-Radio : Yes</li>
          <li>Antenna Options: Internal</li>
        </>
      ),
      downloadLink: AP33,
      hyperlinks: [],
    },

    //AP43
    {
      images: [
        require("../../../assets/productImages/AP43-1.png"),
        require("../../../assets/productImages/AP43-2.png"),
        require("../../../assets/productImages/AP43-1.png"),
        require("../../../assets/productImages/AP43-2.png"),
      ],
      title: "AP43",
      about:
        "The AP43 Series works in conjunction with the Juniper Mist Cloud Architecture driven by Mist AI to collect and analyze metadata in near real-time from all wireless clients.",
      enterpriseFeature: (
        <>
          <li>Deployment: Indoor</li>
          <li>IoT Interface : Yes</li>
          <li>IoT Sensors: Humidity, Pressure, Temperature</li>
        </>
      ),
      downloadLink: AP43,
      hyperlinks: [],
    },
  ];

  const getProductList = () => {
    switch (currentSubproduct) {
      case 1:
        return productDataList1;
      case 2:
        return productDataList2;
      case 3:
        return productDataList3;
      default:
        return [];
    }
  };

  const indexOfLastProduct = currentPage * productsPerPage;
  const indexOfFirstProduct = indexOfLastProduct - productsPerPage;
  const currentProducts = getProductList().slice(
    indexOfFirstProduct,
    indexOfLastProduct
  );

  const paginate = (pageNumber) => {
    setCurrentPage(pageNumber);
    window.scrollTo(0, 0);
  };

  const switchSubproduct = (subproductNumber) => {
    setCurrentSubproduct(subproductNumber);
    setCurrentPage(1);
    window.scrollTo(0, 0);
  };

  return (
    <div className="juniperMist-page">
      <Helmet>
        <title>Juniper Mist - Dealer in Pune Mumbai Maharashtra India</title>
        <meta name="description" content="Your website description here" />

        {/* Dealers Tags */}
        <meta name="keywords" content="Juniper Mist Dealer in Pune" />
        <meta name="keywords" content="Best Juniper Mist Dealer in Pune" />
        <meta name="keywords" content="Juniper Mist Distributor in Pune" />

        <meta name="keywords" content="Juniper Mist Dealer in Mumbai" />
        <meta name="keywords" content="Best Juniper Mist Dealer in Mumbai" />
        <meta name="keywords" content="Juniper Mist Distributor in Mumbai" />

        <meta name="keywords" content="Juniper Mist Dealer in Maharashtra" />
        <meta name="keywords" content="Best Juniper Mist Dealer in Maharashtra" />
        <meta name="keywords" content="Juniper Mist Distributor in Maharashtra" />

        <meta name="keywords" content="Juniper Mist Dealer in Vadodara" />
        <meta name="keywords" content="Best Juniper Mist Dealer in Vadodara" />
        <meta name="keywords" content="Juniper Mist Distributor in Vadodara" />

        <meta name="keywords" content="Juniper Mist Dealer in Surat" />
        <meta name="keywords" content="Best Juniper Mist Dealer in Surat" />
        <meta name="keywords" content="Juniper Mist Distributor in Surat" />

        <meta name="keywords" content="Juniper Mist Dealer in Ahmedabad" />
        <meta name="keywords" content="Best Juniper Mist Dealer in Ahmedabad" />
        <meta name="keywords" content="Juniper Mist Distributor in Ahmedabad" />

        <meta name="keywords" content="Juniper Mist Dealer in Gujarat" />
        <meta name="keywords" content="Best Juniper Mist Dealer in Gujarat" />
        <meta name="keywords" content="Juniper Mist Distributor in Gujarat" />

        <meta name="keywords" content="Juniper Mist Dealer in Indore" />
        <meta name="keywords" content="Best Juniper Mist Dealer in Indore" />
        <meta name="keywords" content="Juniper Mist Distributor in Indore" />

        <meta name="keywords" content="Juniper Mist Dealer in Madhya Pradesh" />
        <meta name="keywords" content="Best Juniper Mist Dealer in Madhya Pradesh" />
        <meta name="keywords" content="Juniper Mist Distributor in Madhya Pradesh" />

        <meta name="keywords" content="Juniper Mist Dealer in Jaipur" />
        <meta name="keywords" content="Best Juniper Mist Dealer in Jaipur" />
        <meta name="keywords" content="Juniper Mist Distributor in Jaipur" />

        <meta name="keywords" content="Juniper Mist Dealer in Rajasthan" />
        <meta name="keywords" content="Best Juniper Mist Dealer in Rajasthan" />
        <meta name="keywords" content="Juniper Mist Distributor in Rajasthan" />

        <meta name="keywords" content="Juniper Mist Dealer in Noida" />
        <meta name="keywords" content="Best Juniper Mist Dealer in Noida" />
        <meta name="keywords" content="Juniper Mist Distributor in Noida" />

        <meta name="keywords" content="Juniper Mist Dealer in Gurugram" />
        <meta name="keywords" content="Best Juniper Mist Dealer in Gurugram" />
        <meta name="keywords" content="Juniper Mist Distributor in Gurugram" />

        <meta name="keywords" content="Juniper Mist Dealer in Delhi" />
        <meta name="keywords" content="Best Juniper Mist Dealer in Delhi" />
        <meta name="keywords" content="Juniper Mist Distributor in Delhi" />

        <meta name="keywords" content="Juniper Mist Dealer in Kolkata" />
        <meta name="keywords" content="Best Juniper Mist Dealer in Kolkata" />
        <meta name="keywords" content="Juniper Mist Distributor in Kolkata" />

        <meta name="keywords" content="Juniper Mist Dealer in West Bengal" />
        <meta name="keywords" content="Best Juniper Mist Dealer in West Bengal" />
        <meta name="keywords" content="Juniper Mist Distributor in West Bengal" />

        <meta name="keywords" content="Juniper Mist Dealer in Bhubaneswar" />
        <meta name="keywords" content="Best Juniper Mist Dealer in Bhubaneswar" />
        <meta name="keywords" content="Juniper Mist Distributor in Bhubaneswar" />

        <meta name="keywords" content="Juniper Mist Dealer in Odisha" />
        <meta name="keywords" content="Best Juniper Mist Dealer in Odisha" />
        <meta name="keywords" content="Juniper Mist Distributor in Odisha" />

        <meta name="keywords" content="Juniper Mist Dealer in Hyderabad" />
        <meta name="keywords" content="Best Juniper Mist Dealer in Hyderabad" />
        <meta name="keywords" content="Juniper Mist Distributor in Hyderabad" />

        <meta name="keywords" content="Juniper Mist Dealer in Telangana" />
        <meta name="keywords" content="Best Juniper Mist Dealer in Telangana" />
        <meta name="keywords" content="Juniper Mist Distributor in Telangana" />

        <meta name="keywords" content="Juniper Mist Dealer in Visakhapatnam" />
        <meta name="keywords" content="Best Juniper Mist Dealer in Visakhapatnam" />
        <meta name="keywords" content="Juniper Mist Distributor in Visakhapatnam" />

        <meta name="keywords" content="Juniper Mist Dealer in Andhra Pradesh" />
        <meta name="keywords" content="Best Juniper Mist Dealer in Andhra Pradesh" />
        <meta name="keywords" content="Juniper Mist Distributor in Andhra Pradesh" />

        <meta name="keywords" content="Juniper Mist Dealer in Chennai" />
        <meta name="keywords" content="Best Juniper Mist Dealer in Chennai" />
        <meta name="keywords" content="Juniper Mist Distributor in Chennai" />

        <meta name="keywords" content="Juniper Mist Dealer in Coimbatore" />
        <meta name="keywords" content="Best Juniper Mist Dealer in Coimbatore" />
        <meta name="keywords" content="Juniper Mist Distributor in Coimbatore" />

        <meta name="keywords" content="Juniper Mist Dealer in Tamil Nadu" />
        <meta name="keywords" content="Best Juniper Mist Dealer in Tamil Nadu" />
        <meta name="keywords" content="Juniper Mist Distributor in Tamil Nadu" />

        <meta name="keywords" content="Juniper Mist Dealer in Bengaluru" />
        <meta name="keywords" content="Best Juniper Mist Dealer in Bengaluru" />
        <meta name="keywords" content="Juniper Mist Distributor in Bengaluru" />

        <meta name="keywords" content="Juniper Mist Dealer in Karnataka" />
        <meta name="keywords" content="Best Juniper Mist Dealer in Karnataka" />
        <meta name="keywords" content="Juniper Mist Distributor in Karnataka" />






        <meta name="author" content="Your Name or Company" />
        <meta name="robots" content="index, follow" />

        <meta property="og:type" content="website" />
        <meta property="og:title" content="Your Page Title" />
        <meta property="og:description" content="Your website description here" />
        <meta property="og:image" content="URL to your image" />
        <meta property="og:url" content="https://www.yourwebsite.com" />
        <meta property="og:site_name" content="Your Website Name" />
        <meta property="fb:app_id" content="Your Facebook App ID" />

        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="Your Page Title" />
        <meta name="twitter:description" content="Your website description here" />
        <meta name="twitter:image" content="URL to your image" />
        <meta name="twitter:site" content="@YourTwitterHandle" />
        <meta name="twitter:creator" content="@YourTwitterHandle" />

        <meta itemprop="name" content="Your Page Title" />
        <meta itemprop="description" content="Your website description here" />
        <meta itemprop="image" content="URL to your image" />

        <meta name="mobile-web-app-capable" content="yes" />
        <meta name="apple-mobile-web-app-capable" content="yes" />
        <meta name="apple-mobile-web-app-status-bar-style" content="black-translucent" />
        <meta name="apple-mobile-web-app-title" content="Your App Title" />
        <meta name="application-name" content="Your App Title" />

        <meta name="msapplication-TileColor" content="#ffffff" />
        <meta name="msapplication-TileImage" content="URL to your tile image" />
        <meta name="theme-color" content="#ffffff" />

        <link rel="icon" href="/favicon.ico" />
        <link rel="apple-touch-icon" href="/apple-touch-icon.png" />
        <link rel="manifest" href="/manifest.json" />
        <link rel="canonical" href="https://calidtech.com/EnterpriseJuniperMistSubcategoryPage" />
      </Helmet>
      <EnterpriseHeader />
      <EnterpriseCategoryPageNavBar />
      <div className="enterprise-small-heder-navbar">
        {/* {subproductNames[currentSubproduct]} */}
        Enterprise LAN and Wireless - Juniper Mist
      </div>
      <div className="juniperMist-subproduct-nav">
        <button
          className={`juniperMist-subproduct-nav-button ${currentSubproduct === 1 ? "active-juniperMist-subproduct" : ""
            }`}
          onClick={() => switchSubproduct(1)}
        >
          Access Switches
        </button>
        <button
          className={`juniperMist-subproduct-nav-button ${currentSubproduct === 2 ? "active-juniperMist-subproduct" : ""
            }`}
          onClick={() => switchSubproduct(2)}
        >
          Core and Distribution Switch
        </button>
        <button
          className={`juniperMist-subproduct-nav-button ${currentSubproduct === 3 ? "active-juniperMist-subproduct" : ""
            }`}
          onClick={() => switchSubproduct(3)}
        >
          Mist WiFi
        </button>
      </div>

      {currentProducts.map((productData, index) => (
        <EnterpriceProductComponent key={index} {...productData} />
      ))}
      <div className="pagination">
        {getProductList().length > productsPerPage && (
          <ul className="juniperMist-ul">
            {Array(Math.ceil(getProductList().length / productsPerPage))
              .fill()
              .map((_, i) => (
                <li
                  id="-juniperMist-li"
                  key={i}
                  className={currentPage === i + 1 ? "active-juniperMist" : ""}
                >
                  <button
                    className="juniperMist-button"
                    onClick={() => paginate(i + 1)}
                  >
                    {i + 1}
                  </button>
                </li>
              ))}
          </ul>
        )}
      </div>
      <ContactUsBot />
      <Whatsapp />
      <SwitchtoManufacturing />
      <EnterpriseFooter />
    </div>
  );
};

export default EnterpriseJuniperMistSubcategoryPage;
