// EnterpriseAltaiSubcategoryPage.js

import React, { useState } from "react";
import "./EnterpriseAltaiSubcategoryPage.css";
import EnterpriceProductComponent from "../../EntertrpriseComponents/EnterpriceProductComponent";
import EnterpriseCategoryPageNavBar from "../EnterpriseCategoryPageNavBar";
import EnterpriseHeader from "../../EntertrpriseComponents/EnterpriseHeader";
import EnterpriseFooter from "../../EntertrpriseComponents/EnterpriseFooter";
import AltaiBX500 from "../../../assets/pdf/Altai-BX500.pdf";
import AltaiBx600 from "../../../assets/pdf/Altai-Bx600.pdf";
import AltaiBX700 from "../../../assets/pdf/Altai-BX700.pdf";
import AltaiBX750 from "../../../assets/pdf/Altai-BX750.pdf";
import ContactUsBot from "../../../Common Components/ContactUsBot";
import Whatsapp from "../../../Common Components/Whatsapp";
import SwitchtoManufacturing from "../../EntertrpriseComponents/SwitchtoManufacturing";
import { Helmet } from 'react-helmet';


const EnterpriseAltaiSubcategoryPage = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [currentSubproduct, setCurrentSubproduct] = useState(1);
  const productsPerPage = 4;

  // const subproductNames = {
  //   1: "PTP and PtMP - Altai",
  // };

  const productDataList1 = [
    //Altai BX500
    {
      images: [
        require("../../../assets/productImages/Altai-BX500-1.png"),
        require("../../../assets/productImages/Altai-BX500-1.png"),
        require("../../../assets/productImages/Altai-BX500-1.png"),
        require("../../../assets/productImages/Altai-BX500-1.png"),
      ],
      title: "Altai BX500",
      about: (
        <>
          <p>Altai BX500-X is an attractively priced 500 Mbps Point-to-Point outdoor radio unit with N-type connectors for external antenna connection, that operates in the 5 GHz band.</p>
          <p>Calid Technologies is authorized partner, reseller, supplier, vendor and service provider of Altai BX500 in Telangana, Gujarat, Bangalore, Goa, Noida, Rohtak, Gurgaon. Contact us to avail best prices and discount for Altai BX500"
          </p>
        </>
      ),
      enterpriseFeature: (
        <>
          <li>Up to 500 Mbps PtP radio unit.</li>
          <li>4.9 - 6.0 GHz.</li>
          <li>Up to 80 km.</li>
        </>
      ),
      downloadLink: AltaiBX500,
      hyperlinks: [],
    },

    //Altai Bx600
    {
      images: [
        require("../../../assets/productImages/Altai-Bx600-1.png"),
        require("../../../assets/productImages/Altai-Bx600-1.png"),
        require("../../../assets/productImages/Altai-Bx600-1.png"),
        require("../../../assets/productImages/Altai-Bx600-1.png"),
      ],
      title: "Altai Bx600",
      about: (
        <>
          <p>Altai BX600-X is a base station that provides up to 250 Mbps net aggregate throughput while delivering access connectivity for up to 16 subscriber units. It operates in 4.9 to 6 GHz bands." </p>
          <p>Calid Technologies is authorized partner, reseller, supplier, vendor and service provider of Altai Bx600 in Telangana, Gujarat, Bangalore, Goa, Noida, Rohtak, Gurgaon. Contact us to avail best prices and discount for Altai Bx600
          </p>
        </>
      ),
      enterpriseFeature: (
        <>
          <li>Up to 250 Mbps net aggregated throughput.</li>
          <li>Ease of operation and maintenance.</li>
          <li>Single radio supporting multiple bands.</li>
        </>
      ),
      downloadLink: AltaiBx600,
      hyperlinks: [],
    },

    //Altai BX700
    {
      images: [
        require("../../../assets/productImages/Altai-BX700-1.png"),
        require("../../../assets/productImages/Altai-BX700-1.png"),
        require("../../../assets/productImages/Altai-BX700-1.png"),
        require("../../../assets/productImages/Altai-BX700-1.png"),
      ],
      title: "Altai BX700",
      about: (
        <>
          <p>It provides best-in-class radio interference mitigation and wide coverage, superior to any Access Point with a horn or uplink-only beamforming antenna.</p>
          <p>Calid Technologies is authorized partner, reseller, supplier, vendor and service provider of Altai BX700 in Telangana, Gujarat, Bangalore, Goa, Noida, Rohtak, Gurgaon. Contact us to avail best prices and discount for Altai BX700"</p>
        </>
      ),
      enterpriseFeature: (
        <>
          <li>Advanced beamforming antenna.</li>
          <li>Up to 750 Mbps, 3 Gbps per 4 sectors.</li>
          <li>Best-in-class interference immunity.</li>
        </>
      ),
      downloadLink: AltaiBX700,
      hyperlinks: [],
    },

    //Altai BX750
    {
      images: [
        require("../../../assets/productImages/Altai-BX750-1.png"),
        require("../../../assets/productImages/Altai-BX750-1.png"),
        require("../../../assets/productImages/Altai-BX750-1.png"),
        require("../../../assets/productImages/Altai-BX750-1.png"),
      ],
      title: "Altai BX750",
      about: (
        <>
          <p>It provides best-in-class radio interference mitigation and wide coverage, superior to any Access Points with a horn or uplink-only beamforming antenna.</p>
          <p>Calid Technologies is authorized partner, reseller, supplier, vendor and service provider of Altai BX750 in Telangana, Gujarat, Bangalore, Goa, Noida, Rohtak, Gurgaon. Contact us to avail best prices and discount for Altai BX750"
          </p>
        </>
      ),
      enterpriseFeature: (
        <>
          <li>PrimeCarrier for ultimate performance.</li>
          <li>Frequency reuse - 2 per network.</li>
          <li>Best effort class of service.</li>
        </>
      ),
      downloadLink: AltaiBX750,
      hyperlinks: [],
    },
  ];

  const getProductList = () => {
    switch (currentSubproduct) {
      case 1:
        return productDataList1;
      default:
        return [];
    }
  };

  const indexOfLastProduct = currentPage * productsPerPage;
  const indexOfFirstProduct = indexOfLastProduct - productsPerPage;
  const currentProducts = getProductList().slice(
    indexOfFirstProduct,
    indexOfLastProduct
  );

  const paginate = (pageNumber) => {
    setCurrentPage(pageNumber);
    window.scrollTo(0, 0);
  };

  const switchSubproduct = (subproductNumber) => {
    setCurrentSubproduct(subproductNumber);
    setCurrentPage(1);
    window.scrollTo(0, 0);
  };

  return (
    <div className="altai-enterprise-page">
      <Helmet>
        <title>Altai Products Dealer Distibutor in Pune Mumbai Maharashtra India</title>
        <meta name="description" content="Your website description here" />
        {/* Dealer Tags */}
        <meta name="keywords" content="Altai Products Dealer in Pune" />
        <meta name="keywords" content="Best Altai Products Dealer in Pune" />
        <meta name="keywords" content="Altai Products Distributor in Pune" />

        <meta name="keywords" content="Altai Products Dealer in Mumbai" />
        <meta name="keywords" content="Best Altai Products Dealer in Mumbai" />
        <meta name="keywords" content="Altai Products Distributor in Mumbai" />

        <meta name="keywords" content="Altai Products Dealer in Maharashtra" />
        <meta name="keywords" content="Best Altai Products Dealer in Maharashtra" />
        <meta name="keywords" content="Altai Products Distributor in Maharashtra" />

        <meta name="keywords" content="Altai Products Dealer in Vadodara" />
        <meta name="keywords" content="Best Altai Products Dealer in Vadodara" />
        <meta name="keywords" content="Altai Products Distributor in Vadodara" />

        <meta name="keywords" content="Altai Products Dealer in Surat" />
        <meta name="keywords" content="Best Altai Products Dealer in Surat" />
        <meta name="keywords" content="Altai Products Distributor in Surat" />

        <meta name="keywords" content="Altai Products Dealer in Ahmedabad" />
        <meta name="keywords" content="Best Altai Products Dealer in Ahmedabad" />
        <meta name="keywords" content="Altai Products Distributor in Ahmedabad" />

        <meta name="keywords" content="Altai Products Dealer in Indore" />
        <meta name="keywords" content="Best Altai Products Dealer in Indore" />
        <meta name="keywords" content="Altai Products Distributor in Indore" />

        <meta name="keywords" content="Altai Products Dealer in Jaipur" />
        <meta name="keywords" content="Best Altai Products Dealer in Jaipur" />
        <meta name="keywords" content="Altai Products Distributor in Jaipur" />

        <meta name="keywords" content="Altai Products Dealer in Noida" />
        <meta name="keywords" content="Best Altai Products Dealer in Noida" />
        <meta name="keywords" content="Altai Products Distributor in Noida" />

        <meta name="keywords" content="Altai Products Dealer in Gurugram" />
        <meta name="keywords" content="Best Altai Products Dealer in Gurugram" />
        <meta name="keywords" content="Altai Products Distributor in Gurugram" />

        <meta name="keywords" content="Altai Products Dealer in Delhi" />
        <meta name="keywords" content="Best Altai Products Dealer in Delhi" />
        <meta name="keywords" content="Altai Products Distributor in Delhi" />

        <meta name="keywords" content="Altai Products Dealer in Kolkata" />
        <meta name="keywords" content="Best Altai Products Dealer in Kolkata" />
        <meta name="keywords" content="Altai Products Distributor in Kolkata" />

        <meta name="keywords" content="Altai Products Dealer in Bhubaneswar" />
        <meta name="keywords" content="Best Altai Products Dealer in Bhubaneswar" />
        <meta name="keywords" content="Altai Products Distributor in Bhubaneswar" />

        <meta name="keywords" content="Altai Products Dealer in Hyderabad" />
        <meta name="keywords" content="Best Altai Products Dealer in Hyderabad" />
        <meta name="keywords" content="Altai Products Distributor in Hyderabad" />

        <meta name="keywords" content="Altai Products Dealer in Visakhapatnam" />
        <meta name="keywords" content="Best Altai Products Dealer in Visakhapatnam" />
        <meta name="keywords" content="Altai Products Distributor in Visakhapatnam" />

        <meta name="keywords" content="Altai Products Dealer in Chennai" />
        <meta name="keywords" content="Best Altai Products Dealer in Chennai" />
        <meta name="keywords" content="Altai Products Distributor in Chennai" />

        <meta name="keywords" content="Altai Products Dealer in Coimbatore" />
        <meta name="keywords" content="Best Altai Products Dealer in Coimbatore" />
        <meta name="keywords" content="Altai Products Distributor in Coimbatore" />

        <meta name="keywords" content="Altai Products Dealer in Bengaluru" />
        <meta name="keywords" content="Best Altai Products Dealer in Bengaluru" />
        <meta name="keywords" content="Altai Products Distributor in Bengaluru" />

        <meta name="keywords" content="Altai Products Dealer in Tamil Nadu" />
        <meta name="keywords" content="Best Altai Products Dealer in Tamil Nadu" />
        <meta name="keywords" content="Altai Products Distributor in Tamil Nadu" />

        <meta name="keywords" content="Altai Products Dealer in Karnataka" />
        <meta name="keywords" content="Best Altai Products Dealer in Karnataka" />
        <meta name="keywords" content="Altai Products Distributor in Karnataka" />



        <meta name="author" content="Your Name or Company" />
        <meta name="robots" content="index, follow" />
        <meta property="og:type" content="website" />
        <meta property="og:title" content="Your Page Title" />
        <meta property="og:description" content="Your website description here" />
        <meta property="og:image" content="URL to your image" />
        <meta property="og:url" content="https://www.yourwebsite.com" />
        <meta property="og:site_name" content="Your Website Name" />
        <meta property="fb:app_id" content="Your Facebook App ID" />

        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="Your Page Title" />
        <meta name="twitter:description" content="Your website description here" />
        <meta name="twitter:image" content="URL to your image" />
        <meta name="twitter:site" content="@YourTwitterHandle" />
        <meta name="twitter:creator" content="@YourTwitterHandle" />

        <meta itemprop="name" content="Your Page Title" />
        <meta itemprop="description" content="Your website description here" />
        <meta itemprop="image" content="URL to your image" />

        <meta name="mobile-web-app-capable" content="yes" />
        <meta name="apple-mobile-web-app-capable" content="yes" />
        <meta name="apple-mobile-web-app-status-bar-style" content="black-translucent" />
        <meta name="apple-mobile-web-app-title" content="Your App Title" />
        <meta name="application-name" content="Your App Title" />

        <meta name="msapplication-TileColor" content="#ffffff" />
        <meta name="msapplication-TileImage" content="URL to your tile image" />
        <meta name="theme-color" content="#ffffff" />

        <link rel="icon" href="/favicon.ico" />
        <link rel="apple-touch-icon" href="/apple-touch-icon.png" />
        <link rel="manifest" href="/manifest.json" />
        <link rel="canonical" href="https://calidtech.com/EnterpriseAltaiSubcategoryPage" />
      </Helmet>
      <EnterpriseHeader />
      <EnterpriseCategoryPageNavBar />
      <div className="enterprise-small-heder-navbar">
        {/* {subproductNames[currentSubproduct]} */}
        PTP and PtMP UBR - Altai
      </div>
      <div className="altai-enterprise-subproduct-nav">
        <button
          className={`altai-enterprise-subproduct-nav-button ${currentSubproduct === 1 ? "active-altai-enterprise-subproduct" : ""
            }`}
          onClick={() => switchSubproduct(1)}
        >
          PTP and PtMP - Altai
        </button>
      </div>

      {currentProducts.map((productData, index) => (
        <EnterpriceProductComponent key={index} {...productData} />
      ))}
      <div className="pagination">
        {getProductList().length > productsPerPage && (
          <ul className="altai-enterprise-ul">
            {Array(Math.ceil(getProductList().length / productsPerPage))
              .fill()
              .map((_, i) => (
                <li
                  id="altai-enterprise-li"
                  key={i}
                  className={
                    currentPage === i + 1 ? "active-altai-enterprise" : ""
                  }
                >
                  <button
                    className="altai-enterprise-button"
                    onClick={() => paginate(i + 1)}
                  >
                    {i + 1}
                  </button>
                </li>
              ))}
          </ul>
        )}
      </div>
      <ContactUsBot />
      <Whatsapp />
      <SwitchtoManufacturing />
      <EnterpriseFooter />
    </div>
  );
};

export default EnterpriseAltaiSubcategoryPage;
